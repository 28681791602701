import Rollbar from 'rollbar';
import { CreateSingletonProps as TippySingletonProps } from 'tippy.js';

import { ActivityVersion, CommentableAnswer, InitialNotebookState } from '~/notebook/types';

import type {
	BasePageElement,
	Chapter,
	CourseDecisions,
	FamilyId,
	PollClassData,
	QuestionChoice
} from './WebtextManifest';
import type { SpeechstreamApi } from '~/components';

export * from './soomoSpeech';

declare global {
	export interface Document {
		soomo: {
			env: string;
			currentUser?: {
				id: number;
				roles: UserRole[];
				configuration: Record<string, boolean>;
			};
			currentCourse?: {
				id: number;
				name: string;
				time_zone: string;
				theme: string;
			};
			currentEnrollment?: {
				roles: UserEnrollmentRole[];
			};
			ally?: {
				maintainAvailableFilter: string;
			};
			notebook?: {
				initialState: InitialNotebookState;
			};
			data?: {
				course: {
					id: number;
					time_zone: string;
					policy: Record<string, boolean | null | string>;
					setting?: Record<string, boolean | null | string>;
					theme: string;
				};
				page?: {
					familyId: string;
					saveResponseUrl?: string;
					elements: {
						[familyId: string]: BasePageElement;
					};
					elementComments: {
						[familyId: string]: Comment;
					};
					citationReferences: CitationReferences;
					config: {
						elements: {
							[key: string]: ElementConfig;
						};
					};
				};
				toc: {
					config: {
						source_list_url: string;
						[key: string]: any;
						courseDecisions: {
							citation_style: {
								options: any[];
							};
							topic: {
								options: any[];
							};
						};
						elements: {
							[key: string]: any;
						};
					};
					userDecisions: {
						[key: string]: string;
					};
				};
			};
		};
	}

	export interface Error {
		cause?: unknown;
	}

	export interface Window {
		Rollbar?: Rollbar;
		Webtext?: {
			/**
			 * Webtext.Events object is defined in the Core app,`events.coffee` file
			 * @see https://github.com/soomo/soomo/blob/main/app/assets/javascripts/events.coffee
			 */
			Events?: {
				subscribe: <TEvent = any>(eventName: string, listener: (event: TEvent) => void) => void;
				publish: <TPayload = any>(eventName: string, payload: TPayload) => void;
				unsubscribe: <TEvent = any>(eventName: string, listener?: (event: TEvent) => void) => void;
			};
			Page: {
				ProgressIndicator: {
					refreshNeeded: () => void;
				};
			};
		};
		csrfParam: string;
		csrfToken: string;
		Sm: Sm;
		pdfjsWorker: any;
		speechstream: SpeechstreamApi;
	}
}

// Content Model Types
export interface User {
	id: number;
	first_name: string;
	last_name: string;
	email_address: string;
}

export type UserRole =
	| 'soomo_admin'
	| 'soomo_editor'
	| 'editor'
	| 'instructor'
	| 'mentor'
	| 'grader'
	| 'student'
	| 'author';

export type UserEnrollmentRole =
	| 'editor'
	| 'instructor'
	| 'mentor'
	| 'grader'
	| 'student'
	| 'ghost';

export interface Course {
	id: number;
	name: string;
	number: string;
	school_id: number;
}

export interface ManagedMultipleChoiceQuestion {
	familyId: string;
	answered: boolean;
}

export interface ResetMCQsResponse {
	reset_questions: {
		id: number;
		family_id: string;
		randomized_choice_map: Record<string, number>;
	}[];
	reset_count: number;
	seed: number;
}

export interface PageRespondablePolicy {
	policy_name: string;
	initial_response_warning: string;
	attempts_allowed: number;
	reset_confirmation: string;
	graded: boolean;
}

export interface Sm {
	userRegistry: UserRegistry;
}

export interface UserRegistry {
	get: (key: string, options?: Partial<{ localOnly: boolean }>) => string | null;
	set: (key: string, value: string) => void;
	touch: (key: string) => void;
	increment: (key: string, increment: number) => void;
	delete: (key: string) => void;
}

export type ArtifactType =
	| 'article'
	| 'video'
	| 'audio'
	| 'download'
	| 'pdfdownload'
	| 'docxdownload'
	| 'xlsxdownload';

export enum FigureType {
	figure = 'figure',
	table = 'table',
	image = 'image',
	video = 'video',
	audio = 'audio',
	iframe = 'iframe',
	chart = 'chart',
	map = 'map',
	html = 'html',
	big_quote_epigraph = 'big_quote_epigraph'
}

export enum GeoMapType {
	US = 'us',
	World = 'world'
}

export interface GeoMapColor {
	name: string;
	color: string;
	id: string;
}

export interface GeoMapData {
	[key: string]: Array<string | number>;
}

export enum LegendVerticalPosition {
	top = 'top',
	bottom = 'bottom'
}

export enum LegendHorizontalPosition {
	left = 'left',
	right = 'right'
}

export enum GeoMapScaleType {
	default = 'default',
	custom = 'custom'
}

export interface MCQuestionChoice extends Omit<QuestionChoice, 'shortened_body'> {
	metadata: Record<string, any>;
	body: string; //HTML - The body of the question choice
	rejoinder: string;
	is_correct: boolean;
	type: 'NG::Soomo::MC::Choice'; // todo: Intersect other choice types as found
}

export type MCQuestionCorrectChoice = Pick<MCQuestionChoice, 'body' | 'rejoinder' | 'family_id'>;

// Respondables
export interface QuizResponse {
	assignment_family_id: string;
	body: string;
	completed_answer_count: number;
	correct_answer_count: number;
	course_id: number;
	created_at: string;
	deleted_at: string;
	first_saved_at: string;
	id: number;
	is_reset: boolean;
	last_saved_at: string;
	last_source: string;
	name: string;
	parent_id: number;
	seed: number;
	total_answer_count: number;
	type: string;
	updated_at: string;
	user_id: number;
	word_count: number;
}

export interface Answer {
	id: number;
	body: string;
	data?: any;
	completed: boolean;
	correct: boolean;
	created_at: string;
	updated_at: string;
	first_saved_at: string;
	question_family_id: string;
	quiz_response_id: number;
}

export interface PollAnswer extends Omit<Answer, 'data'> {
	graphData?: PollClassData;
}

export interface AnswerWithJob extends Answer {
	job?: UserBackgroundJob;
}

export interface VoiceRecordingAnswer extends AnswerWithJob {
	recordingUrl: string;
	transcript: string;
	connected?: boolean;
	recordingStatus: string;
}

export interface GoReactReviewSummary {
	freeformCommentCount: number;
	markerCount: number;
	submittedRubricCount: number;
}

export interface GoReactReviewsSummary {
	[sessionId: string]: GoReactReviewSummary;
}

export type GoReactAnswerProps =
	| { postedRecording: boolean } // scoreOptions: pointsForCompletion => completionOptions.recordingMaking.required
	| { selfReviewSummary: GoReactReviewsSummary } // scoreOptions: pointsForCompletion => completionOptions.selfReview.required
	| { providedReviewSummary: GoReactReviewsSummary } // scoreOptions: pointsForCompletion => completionOptions.providedReview.required
	| { peerReviewSummary: GoReactReviewsSummary } // scoreOptions: pointsForCompletion => completionOptions.nonSelfReview.required
	| { postedRecording: boolean; instructorPoints?: { points: number; possiblePoints: number } }; // scoreOptions: instructorGrade

export type GoReactAnswer = GoReactAnswerProps & Omit<AnswerWithJob, 'data'>;

export interface MCQuestionAnswer extends Answer {
	rejoinder: string;
}

/**
 * A `ResponseBoard` or `ShortAnswer` question answer
 */
export type QuestionAnswer = Answer &
	CommentableAnswer & {
		posted_at: string;
		pinned_at: string;
		pinned_by_name: string | null;
		reference_answer: string;
		history?: Array<ActivityVersion>;
	};

export interface LmsSubmissionProgress {
	step: number;
	states: Array<string>;
	error: boolean;
	cancelled: boolean;
	message?: string;
}

export interface VoiceRecordingElement {
	course_id: number;
	chapter_id: number;
	page_id: number;
	family_id: string;
	phone: string;
	instructions: string;
	pin: string;
	autosubmit: boolean;
	lms_name: string;
	autosubmit_button_label: string;
	type: string;
}

export interface UserBackgroundJob {
	id: number;
	jid: number;
	status: JobStatus;
	progress: any;
	user_id: number;
	answer_id: number;
	updated_at: string;
}

/**
 * Statuses for a UserBackgroundJob defined in the Core app
 * @see https://github.com/soomo/soomo/blob/main/app/models/user_background_job.rb
 */
export enum JobStatus {
	WAITING = 'WAITING',
	RUNNING = 'RUNNING',
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
	CANCELLED = 'CANCELLED'
}

export interface SocketEvent {
	resent?: boolean;
}

export interface UserBackgroundJobEvent<TStates = any> extends SocketEvent {
	type: 'webtext.interaction.update';
	job_id: number;
	jobStatus: JobStatus;
	progress?: { states: TStates; step: number } | { error: boolean } | { cancelled: boolean };
	course_id: number;
	respondable_family_id: string;
	updated_at: string;
	user_id: number;
	worker?: string;
}

export interface DisplayedContent {
	wholeItems: Array<FamilyId>;
	stems: Array<FamilyId>;
}

export interface QuizResponseUpdateEvent extends SocketEvent {
	type: 'webtext.interaction.update';
	course_id: number;
	respondable_family_id: string;
	user_id: number;
}

export interface AllyOptions {
	maintainAvailableFilter: string;
	disableFocusTrapping?: boolean;
}

export interface PollingOptions {
	disabled: boolean;
	scrollListenerSelector: string;
}

export interface ElementContextData {
	courseId: number;
	pageFamilyId: string;
}

export interface Book {
	book: {
		config: {
			course_decisions?: CourseDecisions;
		};
	};
	chapters: Chapter[];
}

export interface ResponseBoardPost {
	updated_at?: string;
	id?: number;
	isPosted?: boolean;
	body: string;
	postedAt: null | string;
	validationMessage?: string;
	lastSavedAt?: string;
	author: { name: string };
	isPinned: boolean;
	pinnedBy: null | string;
}

export type { QuestionApi } from '~/components/shared/Question';

export enum ChartType {
	bar = 'bar',
	line = 'line',
	pie = 'pie'
}

export enum ChartOrientation {
	horizontal = 'horizontal',
	vertical = 'vertical'
}

export enum ChartSeriesOrientation {
	grouped = 'grouped',
	stacked = 'stacked'
}

export enum ChartAxisType {
	normal = 'normal',
	tilted = 'tilted'
}

export enum ChartXAxisType {
	date = 'datetime',
	number = 'linear'
}

export enum ChartDateFormat {
	'Auto-formatted' = '',
	'Full month ("January")' = '%B',
	'Full year ("2001")' = '%Y',
	'Short month and full year ("Jan 2001")' = '%b %Y',
	'Full month and full year ("January 2001")' = '%B %Y'
}

export interface ChartPayload {
	figure_number: string;
	caption?: string;
	title: string;
	description: string;
	credit: string;

	data: Array<any>;
	chart_type: ChartType;
	orientation: ChartOrientation;
	series_orientation: ChartSeriesOrientation;
	colors: Array<any>;
	min_bound?: string;
	max_bound?: string;
	axis_type: ChartAxisType;
	margins: [string, string, string, string];
	show_labels?: boolean;
	show_markers?: boolean;
	allow_zoom?: boolean;

	x_axis_type?: ChartXAxisType;
	date_format?: ChartDateFormat;
	fill_line?: boolean;
	x_ticks?: string;
	x_axis_label?: string;
	y_axis_label?: string;
	line_stroke_width?: string;

	type: ElementType.Chart;
	tabsUi?: boolean;
	round_to?: number;
	labels_width?: number;

	onFileDownload?: (url: string, defaultFileName?: string) => Promise<boolean>;
	onTabChange?: (tabValue: string) => void;

	tippyProps?: Partial<TippySingletonProps>;
}

export interface TimelineDate {
	year: number;
	month?: number;
	day?: number;
	hour?: number;
	minute?: number;
	second?: number;
	millisecond?: number;
	display_date?: string;
}
export interface TimelineText {
	headline?: string;
	text?: string;
}
export interface TimelineMedia {
	url: string;
	caption?: string;
	credit?: string;
	thumbnail?: string;
	alt?: string;
	title?: string;
	link?: string;
	link_target?: string;
}
export interface TimelineBackground {
	url?: string;
	color?: string;
	alt?: string;
}
export interface TimelineEra {
	start_date: TimelineDate;
	end_date: TimelineDate;
	text?: TimelineText;
}
export enum TimelineScale {
	human = 'human',
	cosmological = 'cosmological'
}

export enum TimelineDateFormat {
	'Full date and time' = 'yyyy/MM/dd h:mm aa',
	'Full date' = 'yyyy/MM/dd',
	'Year and month' = 'yyy MMMM',
	'Year' = 'yyyy'
}
export interface TitleTimelineSlide extends Omit<TimelineSlide, 'start_date'> {
	start_date?: TimelineDate;
}
export interface TimelineSlide {
	start_date: TimelineDate;
	start_date_format?: TimelineDateFormat;
	end_date?: TimelineDate;
	end_date_format?: TimelineDateFormat;
	text?: TimelineText;
	media?: TimelineMedia;
	group?: string;
	display_date?: string;
	background?: TimelineBackground;
	autolink?: boolean;
	unique_id?: string;
}
export interface TimelineJsonData {
	events: Array<TimelineSlide>;
	title?: TitleTimelineSlide;
	eras?: Array<TimelineEra>;
	scale?: TimelineScale;
}
export interface TimelineJsonOptions {
	font?: string;
	debug?: boolean;
	height?: number;
	width?: number;
	is_embed?: boolean;
	hash_bookmark?: boolean;
	default_bg_color?: string;
	scale_factor?: number;
	initial_zoom?: number;
	zoom_sequence?: Array<number>;
	timenav_position?: 'top' | 'bottom';
	optimal_tick_width?: number;
	base_class?: string;
	timenav_height?: number;
	timenav_height_percentage?: number;
	timenav_mobile_height_percentage?: number;
	timenav_height_min?: number;
	marker_height_min?: number;
	marker_width_min?: number;
	marker_padding?: number;
	start_at_slide?: number;
	start_at_end?: boolean;
	menubar_height?: number;
	use_bc?: boolean;
	duration?: number;
	ease?: (t: number) => number;
	dragging?: boolean;
	trackResize?: boolean;
	slide_padding_lr?: number;
	slide_default_fade?: string;
	language?: string;
	ga_property_id?: string | null;
	track_events?: Array<'back_to_start' | 'nav_next' | 'nav_previous' | 'zoom_in' | 'zoom_out'>;
	script_path?: string;
	soundcite?: boolean;
}
export type TimelineCallback = (slideId: string) => void;
export interface TimelinePayload {
	type: ElementType.Timeline;
	data: TimelineJsonData;
	options?: TimelineJsonOptions;
	figure_number: string;
	description: string;
	caption: string;
	credits: string;
	instructions: string;
	touchInstructions: string;
	onSlideChange?: TimelineCallback;
}

export enum ElementType {
	HTMLText = 'NG::Soomo::Text',
	MCQuestion = 'NG::Soomo::MC::Question',
	MCChoice = 'NG::Soomo::MC::Choice',
	Figure = 'NG::Soomo::Figure',
	GeoMap = 'NG::Soomo::GeoMap',
	Poll = 'NG::Soomo::Poll::Question',
	PollChoice = 'NG::Soomo::Poll::Choice',
	SAQuestion = 'NG::Soomo::Question',
	YourTurn = 'NG::Soomo::QuestionSet',
	Builder = 'NG::Soomo::Builder',
	Artifact = 'NG::Artifacts::Article',
	ArtifactArticle = 'NG::Artifacts::Article',
	ArtifactAudio = 'NG::Artifacts::Audio',
	ArtifactVideo = 'NG::Artifacts::Video',
	ArtifactDownload = 'NG::Artifacts::Download',
	ArtifactPdfDownload = 'NG::Artifacts::PdfDownload',
	ArtifactDocxDownload = 'NG::Artifacts::DocxDownload',
	ArtifactXlsxDownload = 'NG::Artifacts::XlsxDownload',
	Chart = 'NG::Soomo::Chart',
	PopupQuiz = 'NG::Soomo::Popup::Quiz',
	ExternalTool = 'NG::Soomo::ExternalTool',
	NestingInstruction = 'NG::Soomo::NestingInstruction',
	Decision = 'NG::Soomo::Decision',
	VoiceRecording = 'NG::Soomo::VoiceRecording',
	PageProgress = 'NG::Soomo::PageProgress',
	Timeline = 'NG::Soomo::Timeline',
	Cosmo = 'NG::Soomo::Cosmo',
	GetTheGist = 'NG::Soomo::MC::QuestionSet'
}

export type CitationReferences = Record<
	string,
	{
		citation: string;
		reference: string;
		source_id: string;
	}
>;

export type NestedContentType = 'webtext-primary-source' | 'webtext-callout' | 'webtext-example';

export interface Comment {
	id: number;
	thingId: string;
	comment: string;
	commentedAt: string;
	commentAuthorName: string;
	commentSeenAt: string;
}

export interface ElementConfig {
	enabled: boolean;
	heading_text: string;
	human_name: string;
	settings?: {
		citation_style_help_content: string;
		when_unsatisfied_style: string;
	};
}

export interface ToolConfiguration {
	humanName: string;
	launchURL: string;
	launchParams: Record<string, string>;
	toolHeight: string;
}
