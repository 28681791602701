import { RefObject, useCallback, useState } from 'react';

import { useDebounceCallback, useEventListener, useResizeObserver } from 'usehooks-ts';

interface UseOverflowSidesOptions<T extends HTMLElement> {
	ref: RefObject<T>;
	includeBounceScrolling?: boolean;
	strictBoundaryCheck?: boolean;
}

interface OverflowSides {
	top: boolean;
	bottom: boolean;
	left: boolean;
	right: boolean;
}

/**
 * A hook to calculate which sides of a container have overflowing content.
 *
 * @template T The type of the HTML element being observed.
 * @param {Object} options - Options for configuring the overflow calculation.
 * @param {RefObject<T>} options.ref - A React ref pointing to the container element.
 * @param {boolean} [options.includeBounceScrolling=false] -
 *        If `true`, includes bounce scrolling behavior in calculations.
 *        Bounce scrolling is a behavior observed in iOS where content can be
 *        dragged beyond its scrollable boundaries, causing temporary negative
 *        `scrollTop`/`scrollLeft` values or values exceeding the content height/width.
 *        When this option is enabled:
 *        - Overflow sides will consider these extra movements as part of the overflow.
 *        - Calculations for `isTop`, `isBottom`, `isLeft`, and `isRight` are adjusted
 *          to detect positions slightly outside the normal scroll boundaries.
 *        If `false`, calculations strictly adhere to the boundaries of the content.
 * @param {boolean} [options.strictBoundaryCheck=false] -
 *        If `true`, uses a strict comparison to determine whether the container
 *        has reached the edge of its scrollable area. This means that for vertical
 *        calculations, `clientHeight` must be strictly less than `scrollHeight - scrollTop`
 *        for the content to be considered between boundaries, and similarly for horizontal
 *        calculations. This approach minimizes floating-point inaccuracies and ensures
 *        precise boundary detection.
 *
 *        If `false` (default), allows for a more flexible boundary detection by using a small
 *        tolerance (`> 1px` difference) to account for potential inconsistencies
 *        in `scrollHeight` and `clientHeight` due to browser rendering quirks.
 *        This can be useful when dealing with fractional pixel rounding or
 *        certain layout behaviors where exact equality checks might fail.
 *
 * @returns {OverflowSides} An object indicating which sides (top, bottom, left, right) have overflow.
 */
export const useOverflowSides = <T extends HTMLElement = HTMLElement>(
	options: UseOverflowSidesOptions<T>
): OverflowSides => {
	const { ref: elementRef, includeBounceScrolling, strictBoundaryCheck } = options;

	const [overflowSides, setOverflowSides] = useState<OverflowSides>({
		top: false,
		bottom: false,
		left: false,
		right: false
	});

	const getOverflowSides = useCallback(() => {
		const { current: element } = elementRef;
		if (!element) return;

		const { clientHeight, clientWidth, scrollHeight, scrollWidth, scrollTop, scrollLeft } = element;

		const isExactBoundary = (value: number, boundary: number) => value === boundary;
		const isWithinBoundary = (value: number, boundary: number) => value <= boundary;
		const isBeyondBoundary = (value: number, boundary: number) => value >= boundary;

		const isBottom = includeBounceScrolling
			? isBeyondBoundary(clientHeight, scrollHeight - scrollTop)
			: isExactBoundary(clientHeight, scrollHeight - scrollTop);

		const isTop = includeBounceScrolling
			? isWithinBoundary(scrollTop, 0)
			: isExactBoundary(scrollTop, 0);

		const isBetweenVertically =
			scrollTop > 0 &&
			(strictBoundaryCheck
				? clientHeight < scrollHeight - scrollTop
				: Math.abs(scrollHeight - scrollTop - clientHeight) > 1);

		// There's no overflow, both sides are visible simultaneously
		const isBothVisibleVertically = isTop && isBottom;

		const isRight = includeBounceScrolling
			? isBeyondBoundary(clientWidth, scrollWidth - scrollLeft)
			: isExactBoundary(clientWidth, scrollWidth - scrollLeft);

		const isLeft = includeBounceScrolling
			? isWithinBoundary(scrollLeft, 0)
			: isExactBoundary(scrollLeft, 0);

		const isBetweenHorizontally =
			scrollLeft > 0 &&
			(strictBoundaryCheck
				? clientWidth < scrollWidth - scrollLeft
				: Math.abs(scrollWidth - scrollLeft - clientWidth) > 1);

		// There's no overflow, both sides are visible simultaneously
		const isBothVisibleHorizontally = isLeft && isRight;

		setOverflowSides({
			top: (isBottom || isBetweenVertically) && !isBothVisibleVertically,
			bottom: (isTop || isBetweenVertically) && !isBothVisibleVertically,
			left: (isRight || isBetweenHorizontally) && !isBothVisibleHorizontally,
			right: (isLeft || isBetweenHorizontally) && !isBothVisibleHorizontally
		});
	}, [elementRef, includeBounceScrolling, strictBoundaryCheck]);

	useEventListener('scroll', useDebounceCallback(getOverflowSides, 10), elementRef, {
		passive: true
	});

	useResizeObserver({ ref: elementRef, onResize: useDebounceCallback(getOverflowSides, 100) });

	return overflowSides;
};
