import { select } from 'd3-selection';
import modulo from 'just-modulo';
import { round, inRange } from 'lodash-es';
import tippy, { Instance as TippyInstance } from 'tippy.js';

import { RefreshPollProps } from '~/components/pageElements/PollQuestion';
import { createTooltipsSingleton } from '~/components/pageElements/PollQuestion/utils';
import { elementsOverlap } from '~/utils/elements';

/**
 * The pie chart circle is divided into 4 sectors:
 * 1. [225, 135) - label left, line at right
 * 2. [135, 45) - label at top, line at bottom
 * 3. [45, 315) - label right, line at left
 * 4. [315, 225) - label at bottom, line at top
 *
 * @see https://ibb.co/s6Sc8TB
 * @see https://soomo.height.app/T-95353
 */
const circleSectorsRanges = {
	1: [135, 224.9],
	2: [45, 134.9],
	3: [315, 44.9],
	4: [225, 314.9]
};
type CircleSector = keyof typeof circleSectorsRanges;

export function assignSector(labels: any): void {
	labels.each(function () {
		const label = select(this);
		label.attr('data-circle-sector', function () {
			const x = this.getAttribute('x');
			const y = this.getAttribute('y') * -1; // Y axis is inverted in D3

			let angleRad = Math.atan2(y, x);
			angleRad = angleRad < 0 ? angleRad + 2 * Math.PI : angleRad;

			const angleDeg = round(angleRad * (180 / Math.PI), 1);

			for (const [sector, [start, end]] of Object.entries(circleSectorsRanges)) {
				if (getAngeInRange(angleDeg, start, end)) {
					return sector;
				}
			}
		});
	});
}

/**
 * Checks if the angle is in the range using 360 degrees
 * @see https://stackoverflow.com/a/66834497/10963661 (Plus visual explanation)
 *
 * `modulo` is used because in JS `%` is a "remainder" operator, not a "modulus" (Euclidean division) operator
 */
function getAngeInRange(angle: number, start: number, end: number): boolean {
	return modulo(angle - start, 360) <= modulo(end - start, 360);
}

/**
 * Position and align labels against their lines by the corresponding sector
 * @see {circleSectorsRanges}
 */
const labelAnchorTransformBySector: Record<
	CircleSector,
	(position: { x: number; y: number; w: number; h: number }) => { x: number; y: number }
> = {
	1: ({ x, y, w, h }) => ({ x: x - w, y: y - h / 2 }), // Label on left from line
	2: ({ x, y, w, h }) => ({ x: x - w / 2, y: y - h }), // Label on top from line
	3: ({ x, y, h }) => ({ x, y: y - h / 2 }), // Label on right from line
	4: ({ x, y, w }) => ({ x: x - w / 2, y }) // Label at bottom from line
};

export function positionLabelsBySector(labels: any): void {
	labels.each(function () {
		const label = select(this);
		const labelSector = label.attr('data-circle-sector');

		const x = parseFloat(label.attr('x'));
		const y = parseFloat(label.attr('y'));

		const textContainer = label.select('.pie-chart-label-text-container'); // Only text container has width & height dimensions defined
		const { width: w, height: h } = (textContainer.node() as any).getBoundingClientRect();

		const newLabelAnchor = labelAnchorTransformBySector[labelSector]({ x, y, w, h });
		label.attr('x', newLabelAnchor.x).attr('y', newLabelAnchor.y);
	});
}

// TODO Dedupe with D3PieChart.tsx
/**
 * Prevents recursion overflow when there's no space to relax labels further.
 * Usually it takes ~70 iterations to relax 15 labels.
 * But the maximums recursive stack on the usual machine is ~10k operations
 */
const maxRelaxLabelsIterations = 5000;

/* eslint-disable @typescript-eslint/no-this-alias */
export function removeLabelsOverlap({ chartGroup, labels, lines, iteration = 0 }): void {
	let repeatRelaxation = false;
	const relaxationDelta = 0.5;
	const labelsPadding = {
		x: 7,
		y: 0 // Label's `line-height` is sufficient to provide enough space between labels
	};

	if (iteration >= maxRelaxLabelsIterations) return;
	const newIteration = iteration + 1;

	/**
	 * The possible Y range for the text labels is the equal to:
	 * [-height/2; height/2 - labelElementHeight]
	 *
	 * The label Y position is calculated against the center of the chart. And it specifies the top of the box
	 * Higher - <0
	 * Lower - >0
	 */
	const chartElement = chartGroup.node().parentNode; // Parent SVG element
	if (!chartElement) {
		// The chart component has been re-rendered and the `chartGroup` node doesn't exist in the DOM anymore
		return;
	}

	const { width: chartWidth, height: chartHeight } = chartElement.getBoundingClientRect();
	const leftBoundary = -chartWidth / 2;
	const topBoundary = -chartHeight / 2;

	labels.each(function (_, indexA) {
		const labelElementA = this;
		const labelA = select(labelElementA);

		const textContainerA = labelA.select('.pie-chart-label-text-container');
		const textContainerElementA: any = textContainerA.node(); // Only text container has width & height dimensions defined

		const xA = parseFloat(labelA.attr('x'));
		const yA = parseFloat(labelA.attr('y'));
		const sectorA = parseFloat(labelA.attr('data-circle-sector')) as CircleSector;

		const { width: widthA, height: heightA } = textContainerElementA.getBoundingClientRect();
		const rightBoundaryA = chartWidth / 2 - widthA;
		const bottomBoundaryA = chartHeight / 2 - heightA;

		const lineA = select(lines.nodes()[indexA]);
		const lineXA = parseFloat(lineA.attr('x2'));
		const lineYA = parseFloat(lineA.attr('y2'));

		labels.each(function (_, indexB) {
			const labelElementB = this;
			const labelB = select(labelElementB);

			const textContainerB = labelB.select('.pie-chart-label-text-container');
			const textContainerElementB: any = textContainerB.node();

			const xB = parseFloat(labelB.attr('x'));
			const yB = parseFloat(labelB.attr('y'));
			const sectorB = parseFloat(labelB.attr('data-circle-sector')) as CircleSector;

			const { width: widthB, height: heightB } = textContainerElementB.getBoundingClientRect();
			const rightBoundaryB = chartWidth / 2 - widthB;
			const bottomBoundaryB = chartHeight / 2 - heightB;

			const lineB = select(lines.nodes()[indexB]);
			const lineXB = parseFloat(lineB.attr('x2'));
			const lineYB = parseFloat(lineB.attr('y2'));

			/**
			 * Relaxation breaking conditions
			 */
			if (labelElementA === labelElementB) return;
			if (!elementsOverlap(textContainerElementA, textContainerElementB, labelsPadding)) return; // There's enough space already

			repeatRelaxation = true;

			/*
			Comparing the tips of the lines to obtain the order of the labels
			It's more precise than comparing the label's coordinates that can vary depending on the label's width
			 */
			const deltaX = lineXA - lineXB;
			const adjustX = Math.sign(deltaX) * relaxationDelta;

			const deltaY = lineYA - lineYB;
			const adjustY = Math.sign(deltaY) * relaxationDelta;

			switch (sectorA) {
				// Move labelA horizontally
				case 2:
				case 4: {
					const newLabelXA = xA + adjustX;
					if (inRange(newLabelXA, leftBoundary, rightBoundaryA)) {
						labelA.attr('x', newLabelXA);
						lineA.attr('x2', lineXA + adjustX);
					}
					break;
				}
				// Move labelA vertically
				case 1:
				case 3: {
					const newLabelYA = yA + adjustY;
					if (inRange(newLabelYA, topBoundary, bottomBoundaryA)) {
						labelA.attr('y', newLabelYA);
						lineA.attr('y2', lineYA + adjustY);
					}
					break;
				}
			}

			switch (sectorB) {
				// Move labelB horizontally
				case 2:
				case 4: {
					const newLabelXB = xB - adjustX;
					if (inRange(newLabelXB, leftBoundary, rightBoundaryB)) {
						labelB.attr('x', newLabelXB);
						lineB.attr('x2', lineXB - adjustX);
					}
					break;
				}
				// Move labelB vertically
				case 1:
				case 3: {
					const newLabelYB = yB - adjustY;
					if (inRange(newLabelYB, topBoundary, bottomBoundaryB)) {
						labelB.attr('y', newLabelYB);
						lineB.attr('y2', lineYB - adjustY);
					}
				}
			}
		});
	});

	if (repeatRelaxation) {
		removeLabelsOverlap({ chartGroup, labels, lines, iteration: newIteration });
	}
}

export function drawLabelTooltips(labels: any, tippyProps?: RefreshPollProps['tippyProps']) {
	const tooltips: Array<TippyInstance> = [];

	labels.each(function () {
		const label = select(this);
		const labelElement = this as HTMLElement;

		const labelText = label.select('.pie-chart-label-text');
		const labelTextElement: any = labelText.node();

		// The `choice.shortened_body` prop is used. Need to show the full `body`
		const isShortLabel = labelTextElement.classList.contains('shortened-body');

		// The `scrollHeight` is higher than `clientHeight` when some text is overflown and hidden
		const isOverflownLabel = labelTextElement.scrollHeight > labelTextElement.clientHeight;

		if (isShortLabel || isOverflownLabel) {
			const content = labelElement.dataset.choiceBody || labelTextElement.innerText;
			tooltips.push(tippy(labelElement, { content }));

			label.attr('tabindex', 0);
			label.attr('role', 'button');
			label.attr('aria-label', 'Show longer pie chart label');
			label.attr('aria-hidden', false);
			labelText.classed('label-underline', true);
		}
	});

	const tooltipsSingleton = createTooltipsSingleton(tooltips, tippyProps);
	return {
		tooltips,
		tooltipsSingleton,
		destroy: () => {
			tooltips.forEach((tooltip) => tooltip.destroy());
			tooltipsSingleton.destroy();
		}
	};
}
