import React, { forwardRef, useEffect, useMemo, useRef } from 'react';

import { ClassNames } from '@emotion/react';
import { select } from 'd3-selection';
import { arc, pie } from 'd3-shape';
import { sumBy } from 'lodash-es';
import { useResizeObserver } from 'usehooks-ts';

import { getFigureCaption, getLongDescription } from '~/components/pageElements/PollQuestion/utils';
import { useMediaBreakpoint } from '~/hooks';
import { colors as themeColors } from '~/styles/themes';
import { convertToPercentageStr, convertToRawPercentage } from '~/utils';
import { includesMarkup } from '~/utils/parsing';

import {
	assignSector,
	positionLabelsBySector,
	removeLabelsOverlap,
	drawLabelTooltips
} from './utils';
import { usePollDataState } from '../../hooks';
import { refreshedPollResultsStyles } from '../../styles';

import type { Props as RefreshedPollResultsProps } from '../ResultsTabs';

type Props = Pick<
	RefreshedPollResultsProps,
	| 'questionFamilyId'
	| 'classData'
	| 'colorPalette'
	| 'choices'
	| 'choiceOrdering'
	| 'description'
	| 'tippyProps'
> & {
	isHighContrast: boolean;
};

const RefreshedPollResultsPie: React.FC<Props> = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		classData,
		colorPalette,
		choices,
		choiceOrdering,
		isHighContrast,
		description,
		tippyProps
	} = props;

	/**
	 * Handle resize events
	 */
	const chartContainerRef = useRef<HTMLDivElement | null>(null);
	const { width = 0 } = useResizeObserver({ ref: chartContainerRef, box: 'border-box' });

	const { orderedClassData } = usePollDataState({
		shapedDataMode: 'percentage',
		choices,
		classData,
		choiceOrdering
	});

	// do not display 0 responses cases
	const pieChartData = useMemo(
		() => orderedClassData.filter((item) => item.data !== 0),
		[orderedClassData]
	);

	const total = sumBy(classData, 'data');
	const isGraphDataEmpty = total === 0;

	const { height, innerRadius, outerRadius, labelRadius, labelWidth } =
		useChartSizes(isGraphDataEmpty);

	const colors = useMemo(
		() => colorPalette.filter((_, index) => orderedClassData[index]?.data !== 0),
		[orderedClassData, colorPalette]
	);

	useEffect(() => {
		const { current: chartContainer } = chartContainerRef;

		/**
		 * Don't try to draw until we can received the size of the container
		 */
		if (!chartContainer || width === 0) return;

		let labelTooltips;

		const draw = () => {
			/**
			 * Pie chart container
			 */
			const svg: any = select(chartContainer)
				.append('svg')
				.attr('aria-hidden', true)
				.attr('width', width)
				.attr('height', height)
				.append('g')
				.attr('transform', `translate(${width / 2}, ${height / 2})`);

			const pieShape = (pie() as any).sort(null).value((d) => d.data);

			const pieData = isGraphDataEmpty ? pieShape([{ data: 1 }]) : pieShape(pieChartData);

			const pieArc = arc().innerRadius(innerRadius).outerRadius(outerRadius);
			const outerArc = arc().innerRadius(outerRadius).outerRadius(outerRadius);
			const labelArc = arc().innerRadius(labelRadius).outerRadius(labelRadius);

			/**
			 * Draw slices
			 */
			svg
				.selectAll('allSlices')
				.data(pieData)
				.enter()
				.append('path')
				.attr('d', pieArc)
				.attr('fill', (d, index) => {
					if (isGraphDataEmpty) return '#cccccc';
					return isHighContrast ? '#f1e2c9' : colors[index];
				})
				.style('stroke', () => (isHighContrast ? themeColors.brown : null));

			// do not draw lines for empty data
			if (isGraphDataEmpty) return;

			/**
			 * Draw lines
			 */
			const lines = svg
				.selectAll('allLines')
				.data(pieData)
				.enter()
				.append('line')
				.style('fill', 'none')
				.attr('stroke-width', 1)
				.attr('x1', (d) => outerArc.centroid(d)[0]) // line start position
				.attr('y1', (d) => outerArc.centroid(d)[1])
				.attr('x2', (d) => labelArc.centroid(d)[0]) // line end position
				.attr('y2', (d) => labelArc.centroid(d)[1])
				.attr('class', 'pie-chart-label-line');

			/**
			 * Draw labels
			 */
			const labels = svg
				.selectAll('allLabels')
				.data(pieData)
				.enter()
				.append('foreignObject')
				.attr('x', (d) => {
					const x = labelArc.centroid(d)[0];
					const xOffset = 5 * Math.sign(x);
					return x + xOffset;
				})
				.attr('y', (d) => labelArc.centroid(d)[1])
				.attr('width', labelWidth)
				.attr('height', 1) // Firefox requires a height >0 to be set. The number is irrelevant, because `overflow: visible` is applied
				.attr('class', 'pie-chart-label')
				.call(assignSector)
				.append('xhtml:div')
				.attr('class', 'pie-chart-label-text-container')
				.attr('data-choice-body', (d) => d.data.label)
				.html((d) => {
					const { shortened_body: shortenedBody, label, data } = d.data;

					const percentage = convertToRawPercentage(data, total);
					const percentageStr =
						percentage > 0 && percentage < 1 ? '<1%' : convertToPercentageStr(data, total, 0);

					if (shortenedBody) {
						return `<div class="pie-chart-label-text shortened-body">
									${shortenedBody}<span class="visually-hidden">.&nbsp;${label}</span>
								</div>
								${percentageStr}`;
					}
					return `<div class="pie-chart-label-text">${label}</div> ${percentageStr}`;
				})
				.attr('aria-hidden', true);

			labelTooltips = drawLabelTooltips(labels, tippyProps);

			svg
				.selectAll('.pie-chart-label')
				.call(positionLabelsBySector)
				.call((labels) => removeLabelsOverlap({ chartGroup: svg, labels, lines }));
		};

		/**
		 * Remove all SVG elements and tooltip instances before redrawing
		 */
		select(chartContainer).selectAll('*').remove();
		labelTooltips?.destroy();

		draw();
	}, [
		chartContainerRef,
		colors,
		height,
		innerRadius,
		isGraphDataEmpty,
		isHighContrast,
		labelRadius,
		labelWidth,
		outerRadius,
		pieChartData,
		tippyProps,
		total,
		width
	]);

	return (
		<ClassNames>
			{({ cx }) => (
				<figure
					ref={ref}
					css={refreshedPollResultsStyles}
					className={cx('refreshed-poll-results', 'refreshed-poll-results-pie')}>
					<div role="img" aria-label={getLongDescription(description, 'pie')} style={{ height: 0 }}>
						&nbsp;
					</div>
					<div ref={chartContainerRef} />
					{includesMarkup(description) && (
						<figcaption
							className="sr-only"
							dangerouslySetInnerHTML={{ __html: getFigureCaption(description) }}
						/>
					)}
				</figure>
			)}
		</ClassNames>
	);
});

RefreshedPollResultsPie.displayName = 'RefreshedPollResultsPie';

export function useChartSizes(chartEmpty: boolean) {
	const isExtraSmallScreen = useMediaBreakpoint('extraSmall', 'max-width');
	const isSmallScreen = useMediaBreakpoint('small', 'max-width');
	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');

	const sizes = {
		height: 450,
		innerRadius: 0,
		outerRadius: 120,
		labelRadius: 150,
		labelWidth: 150
	};
	if (chartEmpty) {
		return { ...sizes, height: 280 };
	}

	if (isExtraSmallScreen) {
		sizes.height = 250;
		sizes.outerRadius = 60;
		sizes.labelRadius = 66;
		sizes.labelWidth = 50;
	} else if (isSmallScreen) {
		sizes.height = 300;
		sizes.outerRadius = 77;
		sizes.labelRadius = 85;
		sizes.labelWidth = 65;
	} else if (isMediumScreen) {
		sizes.height = 390;
		sizes.outerRadius = 100;
		sizes.labelRadius = 115;
		sizes.labelWidth = 110;
	}

	return sizes;
}

export default RefreshedPollResultsPie;
