import { css, SerializedStyles } from '@emotion/react';

import { breakpoints, fonts, getThemeItem, mixins, Theme, ThemeName } from '~/styles/themes';

import { TippyBaseTheme } from './components/WebtextTippy';
import { customListItemBullets, universalVelvetStyles } from './themes';

export const BaseTextStyles = (theme, options?: { containerType?: string; platform?: string }) => {
	const webtextHeadingColor = theme.webtextPage.headings.color;
	const webtextHeadingFontFamily = theme.fonts['webtext-heading-font-family'];

	return css`
		${TippyBaseTheme(options?.platform)}

		font-size: 1rem;
		font-weight: 400;
		color: ${theme.colors.text};
		font-family: ${theme.fonts.webtext};
		max-width: ${theme.layout.contentColumnWidth};
		text-rendering: optimizeLegibility;
		position: relative;
		-webkit-font-smoothing: antialiased;

		// Remove padding to make it less of a large gap between elements.
		// This will likely change in the future when we start working on our systematic spacing goals.
		// .main-webtext-column
		padding-bottom: 0;

		.webtext-page-title {
			font-family: ${theme.fonts['webtext-heading-font-family']};
			font-size: ${theme.webtextPage.title.fontSize};
			color: ${theme.webtextPage.title.color};
			margin-bottom: 15px;
			text-transform: ${theme.webtextPage.title.textTransform};
		}

		.webtext-content-author {
			margin: -10px 0 15px !important;
			font-size: 21px !important;
		}

		.webtext-content-translator {
			font-size: 15px !important;
			margin: 0 0 30px !important;
		}

		.webtext-callout-heading {
			color: ${theme.webtextPage.title.color};
		}

		/**
		Styles for production content
		*/
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		pre,
		dl,
		dd,
		ul,
		ol,
		fieldset,
		blockquote {
			margin: 0;
		}

		p {
			margin-bottom: 30px;
			font-size: ${theme.webtextPage.p.fontSize};
			line-height: 1.6;

			@media (max-width: ${breakpoints.small}) {
				font-size: 15px;
				line-height: 26px;
				letter-spacing: 0.22px;
			}
		}

		blockquote {
			font-family: ${theme.webtextPage.blockquote.fontFamily};
			font-weight: ${theme.webtextPage.blockquote.fontWeight};
			font-style: ${theme.webtextPage.blockquote.fontStyle};
			font-size: ${theme.webtextPage.blockquote.fontSize};
			line-height: ${theme.webtextPage.blockquote.lineHeight};
		}

		span {
			&.key-point {
				padding: 0 10px;
				background: ${theme.colors.primary};
				color: #fefefe;
				text-align: center;
			}

			&.webtext-decoration {
				&.a {
					background-color: #e3bdf0;
					color: #4a2457;
				}

				&.b {
					background-color: #a8dfe2;
					color: #134b4c;
				}

				&.c {
					background-color: #fecf99;
					color: #653600;
				}
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 30px 0 15px;
			color: ${webtextHeadingColor};
			font-family: ${webtextHeadingFontFamily};
			font-weight: ${theme.webtextPage.headings.fontWeight};
			text-transform: ${theme.webtextPage.headings.textTransform};
			line-height: ${theme.webtextPage.headings.lineHeight};
			overflow-wrap: break-word; /* forcefully wrap words if the word would overflow the container */
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			&.webtext-page-title {
				font-family: ${theme.fonts.header};
			}
		}

		h1 {
			font-family: ${theme.webtextPage.h1.fontFamily};
			font-size: ${theme.webtextPage.h1.fontSize};
			margin-top: 0;
		}

		h2 {
			font-family: ${theme.webtextPage.h2.fontFamily};
			font-size: ${theme.webtextPage.h2.fontSize};
		}

		h3 {
			font-family: ${theme.webtextPage.h3.fontFamily};
			font-size: ${theme.webtextPage.h3.fontSize};
		}

		h4 {
			font-family: ${theme.webtextPage.h4.fontFamily};
			font-size: ${theme.webtextPage.h4.fontSize};
		}

		h5 {
			font-family: ${theme.webtextPage.h5.fontFamily};
			font-size: ${theme.webtextPage.h5.fontSize};
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			&.webtext-page-title {
				font-family: ${theme.fonts.header};
			}
		}

		b,
		strong {
			color: ${theme.webtextPage.strong.color};
			font-weight: ${theme.webtextPage.strong.fontWeight};
		}

		hr {
			margin-bottom: 30px;
			box-sizing: content-box;
			height: 0;
		}

		table {
			border-collapse: collapse;
			border-spacing: 0;
		}

		/**
		 * Just Webtext things...
		 */
		.visually-hidden {
			position: absolute !important;
			clip: rect(1px 1px 1px 1px);
			padding: 0 !important;
			border: 0 !important;
			height: 1px !important;
			width: 1px !important;
			overflow: hidden;
		}

		.webtext-big-link {
			clear: both;
			position: relative;
			display: flex;
			flex-direction: column;
			flex-wrap: none;
			justify-content: center;
			cursor: pointer;
			background-color: ${theme.bigLink.backgroundColor};
			text-decoration: none;
			min-height: 70px;
			background-image: url(data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20width%3D%2272px%22%20height%3D%2272px%22%20viewBox%3D%220%200%2072%2072%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%09%3Cg%20fill%3D%22${encodeURIComponent(
				theme.bigLink.icon.fill
			)}%22%3E%0A%09%09%3Cpath%20d%3D%22M55.7%2C44.8c0%2C0.7-0.6%2C1.2-1.2%2C1.2h-37c-0.7%2C0-1.2-0.6-1.2-1.2V20.1c0-0.7%2C0.6-1.2%2C1.2-1.2h37c0.7%2C0%2C1.2%2C0.6%2C1.2%2C1.2V44.8z%20M60%2C19.2c0-2.7-2.2-4.8-4.8-4.8H16.7c-2.7%2C0-4.8%2C2.2-4.8%2C4.8v31.3H60V19.2z%20M40.8%2C53c0%2C0.7-0.5%2C1.2-1.2%2C1.2h-7.2%20c-0.7%2C0-1.2-0.5-1.2-1.2H7.1v2.4c0%2C1.3%2C1.1%2C2.2%2C2.4%2C2.2h53c1.3%2C0%2C2.4-0.9%2C2.4-2.2V53H40.8z%22%2F%3E%0A%09%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A);
			background-repeat: no-repeat;
			background-position: 2px center;
			padding: 3px 0 5px 75px;
			margin: 10px 0;

			&.webtext-big-link-article {
				background-image: url(data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20viewBox%3D%220%200%2072%2072%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2272px%22%20height%3D%2272px%22%3E%0A%09%3Cg%20fill%3D%22${encodeURIComponent(
					theme.bigLink.icon.fill
				)}%22%3E%0A%09%09%3Cpath%20d%3D%22M55.7%2C44.8c0%2C0.7-0.6%2C1.2-1.2%2C1.2h-37c-0.7%2C0-1.2-0.6-1.2-1.2V20.1c0-0.7%2C0.6-1.2%2C1.2-1.2h37c0.7%2C0%2C1.2%2C0.6%2C1.2%2C1.2V44.8z%20M60%2C19.2c0-2.7-2.2-4.8-4.8-4.8H16.7c-2.7%2C0-4.8%2C2.2-4.8%2C4.8v31.3H60V19.2z%20M40.8%2C53c0%2C0.7-0.5%2C1.2-1.2%2C1.2h-7.2%20c-0.7%2C0-1.2-0.5-1.2-1.2H7.1v2.4c0%2C1.3%2C1.1%2C2.2%2C2.4%2C2.2h53c1.3%2C0%2C2.4-0.9%2C2.4-2.2V53H40.8z%22%2F%3E%0A%09%09%3Cpath%20d%3D%22m40.843%2037.316c0.374-0.373%200.373-0.98%200-1.354l-8.333-8.334c-0.181-0.18-0.421-0.28-0.677-0.28s-0.497%200.1-0.678%200.28c-0.18%200.181-0.28%200.421-0.28%200.678%200%200.256%200.1%200.497%200.28%200.678l8.334%208.332c0.361%200.362%200.994%200.361%201.354%200%22%2F%3E%0A%09%09%3Cpath%20d%3D%22m31.784%2030.994l-4.537-4.537c-0.277-0.277-0.278-0.746-1e-3%20-1.025l1.714-1.711c0.269-0.27%200.753-0.27%201.023-1e-3l4.537%204.538c0.139%200.138%200.21%200.329%200.201%200.534l1.655%201.656c0.824-1.156%200.707-2.73-0.305-3.741l-4.535-4.537c-0.55-0.551-1.284-0.854-2.064-0.854-0.781%200-1.514%200.303-2.064%200.854l-1.712%201.712c-0.551%200.55-0.853%201.282-0.853%202.063%200%200.78%200.303%201.513%200.854%202.064l4.536%204.536c0.545%200.545%201.271%200.845%202.044%200.845%200.609%200%201.204-0.191%201.698-0.541l-1.657-1.656c-0.211%200.012-0.397-0.062-0.534-0.199%22%2F%3E%0A%09%09%3Cpath%20d%3D%22m46.303%2036.938l-4.536-4.537c-0.546-0.546-1.272-0.846-2.045-0.846-0.608%200-1.203%200.191-1.697%200.541l1.655%201.656c0.203-0.015%200.397%200.063%200.535%200.199l4.537%204.539c0.135%200.134%200.209%200.314%200.209%200.51%201e-3%200.196-0.074%200.377-0.209%200.512l-1.713%201.713c-0.269%200.269-0.754%200.27-1.024-2e-3l-4.536-4.534c-0.139-0.142-0.21-0.332-0.2-0.536l-1.656-1.655c-0.823%201.155-0.707%202.73%200.305%203.742l4.536%204.536c0.55%200.55%201.283%200.852%202.064%200.853%200.78%200%201.513-0.303%202.063-0.853l1.713-1.712c1.138-1.138%201.137-2.989-1e-3%20-4.126%22%2F%3E%0A%09%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A);
			}

			.webtext-big-link-title {
				color: ${theme.bigLink.title.color};
				font-family: ${theme.bigLink.title.fontFamily};
				font-size: ${theme.bigLink.title.fontSize};
				text-decoration: underline;
				-webkit-text-decoration-color: #949494;
				text-decoration-color: #949494;
			}

			.webtext-big-link-credit {
				display: block;
				color: ${theme.bigLink.credit.color};
				font-style: italic;
				font-size: ${theme.bigLink.credit.fontSize};
				margin-top: 3px;

				a:hover {
					color: ${theme.bigLink.credit.aHoverColor};
				}
			}

			&:hover {
				background-color: ${theme.bigLink.hoverBackgroundColor};
			}
		}

		.webtext-objective {
			font-family: ${theme.global.webtextObjective.fontFamily};

			p {
				font-size: 21px;
			}
		}

		.webtext-page-instruction {
			box-sizing: border-box;
			background: ${theme.global.webtextPageInstruction.backgroundColor};
			color: white;
			font-family: ${theme.global.webtextPageInstruction.fontFamily};
			font-size: ${theme.global.webtextPageInstruction.fontSize};
			font-weight: ${theme.global.webtextPageInstruction.fontWeight};
			${theme.global.webtextPageInstruction.lineHeight &&
			`line-height: ${theme.global.webtextPageInstruction.lineHeight};`}
			padding: ${theme.global.webtextPageInstruction.padding};

			a {
				color: white;
			}
		}

		ol.webtext-page-instruction,
		ul.webtext-page-instruction {
			padding-left: 40px;
		}

		// See - https://soomo.height.app/T-65314#dee0ffd9-5f96-480e-86dd-fea17ba5fc5b
		// Helps to prevent list overlap over the \`pull-left\` figure
		ol.bullet-inside,
		ul.bullet-inside {
			@media (min-width: ${breakpoints.small}) {
				list-style-position: inside !important; // Overrides the children themes definition
			}
		}

		.footnotes {
			font-family: ${theme.fonts.app};
			border-top: 1px solid rgba(31, 31, 31, 0.11);
			margin: 30px 0;
			padding-top: 30px;

			.footnote-anchor {
				position: absolute;
				left: -6px;
				top: -2px;
				display: block;
				margin: 0 0 0 0;
				min-width: 25px;
				color: ${theme.colors.primary};
				font-size: 14px;
				font-weight: bold;
				text-align: right;
				text-decoration: none;

				::before {
					content: '[';
				}

				::after {
					content: ']';
				}
			}

			p {
				font-family: ${theme.fonts.app};
				margin-bottom: 10px;
				padding-left: 35px;
				position: relative;
				font-size: 13px;
				line-height: 1.6;
			}
		}

		.footnote-ref-tail {
			white-space: nowrap;
		}

		.footnote-ref {
			display: inline-block;
			min-width: 20px;
			background-clip: content-box;
			vertical-align: super;
			color: ${getThemeItem(theme.footnote.color, theme)};
			font-family: ${theme.fonts.app};
			font-size: 14px;
			font-style: normal;
			font-weight: bold;
			line-height: 17px;
			text-align: center;
			text-decoration: none;
			cursor: pointer;

			::before {
				content: '[';
			}

			::after {
				content: ']';
			}
		}

		.webtext-article-source {
			font-family: ${theme.global.webtextArticleSource.fontFamily};
			font-size: 14px;
			border-left: ${theme.global.webtextArticleSource.borderLeft};

			// From "nested" content
			margin-bottom: 30px;

			span {
				background-color: #fff;
				padding: 6px 15px;
			}
		}

		.webtext-source-attribution {
			border-top: 1px solid rgba(31, 31, 31, 0.11);
			margin: 30px 0;
			padding-top: 30px;
			font-family: ${theme.fonts.app};
			font-size: 13px;

			p {
				margin-bottom: 1em;
			}

			* {
				font-size: inherit;
				font-family: inherit;
			}
		}

		.glossary {
			.webtext-glossary-term-locations {
				font-family: ${theme.global.webtextGlossaryTermLocations.fontFamily};
				font-size: 0.8em;

				a {
					margin: 0 3px;
				}
			}
		}

		.webtext-apa-reference-list p {
			margin-left: 2.5em;
			text-indent: -2.5em;

			a {
				word-break: break-all;
			}
		}

		ref[data-reference-type='apa'] {
			white-space: initial;
		}

		.webtext-investigation-objective {
			margin: 10px 0 35px;
			color: ${theme.global.webtextInvestigationObjective.color};
			font-family: ${theme.global.webtextInvestigationObjective.fontFamily};
			font-size: ${theme.global.webtextInvestigationObjective.fontSize};

			${[ThemeName.STRAYER_VELVET, ThemeName.STRAYER_VELVET_2].includes(theme.name) &&
			css`
				font-weight: bold;
			`}

			strong {
				color: inherit;
			}

			::after {
				display: block;
				content: '';
				margin-top: 25px;
				border-bottom: 2px solid rgba(31, 31, 31, 0.11);
				width: 50px;
			}
		}

		.webtext-placard {
			background-color: #f7f7f7;
			font-family: ${theme.fonts.app};
			padding: 30px;
			position: relative;
			overflow: auto;
			margin-bottom: 30px;

			p {
				font-size: 16px;
				line-height: 1.4;
			}

			strong {
				font-family: 'HelveticaNeueW01-75Bold', Helvetica, Arial, sans-serif;
				font-weight: normal;
			}

			em {
				font-family: 'HelveticaNeueW01-56It', Helvetica, Arial, sans-serif;
				font-style: normal;
			}

			.artist {
				font-size: 19px;
				font-family: 'HelveticaNeueW01-75Bold', Helvetica, Arial, sans-serif;
				margin: 0 0 20px;
			}

			.artwork {
				width: 50%;
				float: left;

				p {
					margin: 0;
				}
			}

			.description {
				width: 45%;
				float: right;
				position: relative;
				right: 0;
				bottom: 0;

				p {
					margin: 0 0 20px;
				}

				:last-child {
					margin-bottom: 0;
				}
			}
		}

		.webtext-excerpt-credits {
			@media (max-width: ${breakpoints.small}) {
				line-height: 20px;
			}

			border-top: 1px solid rgba(31, 31, 31, 0.11);
			margin: 30px 0 0 0;
			padding-top: 30px;
			font-family: ${theme.fonts.app};
			font-size: 13px;

			* {
				font-size: 13px;
			}
		}

		table.webtext-performance-script {
			font-size: 19px;
			width: 100%;
			line-height: 1.6;
			border: 0;

			tr {
				&.no-gap {
					td {
						padding-bottom: 0;
					}
				}

				td {
					padding-bottom: 30px;
					vertical-align: top;
				}
			}
		}

		*.webtext-source-url {
			word-break: break-all;
		}

		.assignment-objective-header {
			display: block;
			color: ${theme.colors.primary};
			text-transform: uppercase;
			font-size: 12px;
			margin-bottom: 0px !important;
			font-family: ${theme.fonts.app};
			font-style: normal;
		}

		.assignment-objective-body {
			padding: 0;
			margin-bottom: 30px;
			font-family: 'HelveticaNeueW01-65Medi', Helvetica, Arial, sans-serif;
		}

		/* Inline Content (That uses figure styles) */

		.webtext-figure-asset {
			margin-bottom: 16px;
			width: 100%;
		}

		.webtext-figure-caption {
			font-family: ${fonts['haas-grotesk-medium']};
			font-size: 14px;
			margin-bottom: 28px;
			line-height: 20;
			color: #757575;
			border-width: 1px;
		}

		img.webtext-figure-asset-image {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 15px;
			padding-bottom: ${theme.figure.payload.assetImage.paddingBottom};
		}

		table.webtext-figure-asset-table {
			font-size: ${theme.webtextFigureAssetTable.fontSize};
			clear: both;

			th {
				font-size: ${theme.webtextFigureAssetTable
					.headerFontSize}; // $webtext-table-header-font-size
				height: 40px; // $global-header-height
				padding: 10px;
				font-weight: bold;
				background: ${theme.webtextFigureAssetTable.headerBackground};
				border-right: 2px solid ${theme.colors.white};
				color: ${theme.colors.white};
			}

			tr {
				background-color: ${theme.webtextFigureAssetTable.rowBackground};

				&:nth-child(odd) {
					background-color: ${theme.webtextFigureAssetTable.oddRowBackground};
				}
			}

			td {
				border-right: 2px solid ${theme.colors.white};
				padding: 10px;

				&:last-child {
					border: none;
				}

				&.webtext-apa-reference {
					text-indent: -2.5em;
					padding-left: calc(2.5em + 10px);
				}

				strong {
					font-size: 16px;
				}
			}

			ul {
				margin-left: 30px;
				list-style: disc;
			}

			ul li ul {
				margin-top: 8px;
				list-style: circle;
				margin-bottom: 10px;
			}

			li {
				font-size: inherit;
				line-height: inherit;
				padding-left: 5px;
			}

			p {
				font-size: inherit;
			}
		}

		.nowrap {
			white-space: nowrap;
		}

		/**
		 * Links
		 */
		a {
			${linkStyles(theme)}
		}

		/**
		 * List
		 */
		ol,
		ul {
			margin-left: 30px;
			list-style: square;
			padding: 0;

			li {
				ol,
				ul {
					margin-left: 20px;
					margin-bottom: 0px;
				}
			}
		}

		.text-component > ol,
		.text-component > ul {
			margin-bottom: 30px;
		}

		ol {
			list-style: decimal;

			&.webtext-ciag-centralideas-list {
				list-style: upper-roman;
			}
		}

		li {
			padding-left: 10px;
			margin-bottom: 5px;
			margin-top: 5px;
			font-size: ${theme.webtextPage.li.fontSize};
			line-height: 1.6;

			@media (max-width: ${breakpoints.small}) {
				font-size: 15px;
			}
		}

		ol.webtext-twg-outline,
		ol.webtext-outline {
			list-style-type: upper-roman;

			& > li > ol {
				list-style-type: upper-alpha;
			}

			& > li > ol > li > ol {
				list-style-type: decimal;
			}
		}

		// Specifies default styles that can be overridden by editors via inline styles
		.webtext-multicolumn-list {
			column-count: auto;
			column-gap: normal;
			column-width: auto;
		}

		@media (max-width: ${breakpoints.small}) {
			ol,
			ul {
				margin-left: 20px;
				margin-top: 18px;
			}
		}

		/**
		 * Citations
		 */
		ref span[data-source-family-id] {
			cursor: pointer;
			text-decoration: none;
			border-bottom: 1px dotted ${theme.colors.text};
			outline: none;
		}

		/**
		 * Super- and subscripts. See T-163374
		 */
		sup,
		sub {
			&::before,
			&::after {
				${mixins.webtextHiddenAccessible(theme)};
			}
		}

		// n.b. spaces here are intentional
		sub::before {
			content: 'subscript ';
		}

		sub::after {
			content: 'end subscript ';
		}

		sup::before {
			content: 'superscript ';
		}

		sup::after {
			content: 'end superscript ';
		}

		${[ThemeName.STRAYER_VELVET, ThemeName.STRAYER_VELVET_2, ThemeName.QUEENS_VELVET].includes(
			theme.name
		) &&
		options?.containerType !== 'nesting' &&
		customListItemBullets(theme)}

		li.download {
			&::before {
				font-family: 'SSStandard', serif;
				margin-left: -2em;
				padding-right: 1.5em;
				font-size: 14px;
				content: '\\EB01';
				vertical-align: baseline;

				@media (max-width: ${breakpoints.small}) {
					font-size: 11px;
				}
			}

			&::marker {
				content: '';
			}
		}

		${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetStyles(theme)}
	`;
};

export const linkStyles = (theme: Theme): SerializedStyles => css`
	color: ${getThemeItem(theme.colors.link, theme)};
	font-weight: ${theme.webtextPage.a.fontWeight};
	text-decoration: underline;

	&.showtip {
		cursor: pointer;
		text-decoration: none;
		font-weight: bold;
		color: ${getThemeItem(theme.colors.link, theme)};
		border-bottom: 1px dotted ${getThemeItem(theme.colors.link, theme)};
	}
`;
