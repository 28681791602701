import React, {
	cloneElement,
	forwardRef,
	HTMLAttributes,
	isValidElement,
	KeyboardEvent,
	ReactElement,
	useRef,
	useState
} from 'react';

import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Tippy from '@tippyjs/react';
import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';
import { hideAll } from 'tippy.js';

import { Props as TextProps } from '~/components/pageElements/Text/Text';
import { checkForBBCode, checkForSpecialCase } from '~/components/pageElements/Text/utils';
import Toast from '~/components/Toast';
import { getThemeItem, Theme } from '~/styles/themes';

import WebtextTippyStyles from './styles';
import { hideOnEscPlugin } from './utils';

interface WebtextTippyContent {
	text: string;
	element?: JSX.Element;
}

export interface WebtextTippyProps {
	element: Element;
	textProps: TextProps;
	content?: WebtextTippyContent;
}

export const WebtextTippy: React.FC<WebtextTippyProps> = (props) => {
	const { element, content, textProps } = props;

	const copyButtonRef = useRef(null);

	const [isTippyVisible, setTippyVisible] = useState(false);
	const showTippy = () => setTippyVisible(true);
	const hideTippy = () => setTippyVisible(false);

	const [snackbarVisible, setSnackbarVisible] = useState(false);
	const showSnackbar = () => setSnackbarVisible(true);
	const hideSnackbar = () => setSnackbarVisible(false);

	const tippyContent: WebtextTippyContent = content || { text: element.attribs['title'] };

	const isCitationRef = Boolean(
		textProps.citationReferences &&
			textProps.citationReferences[element.attribs['data-source-family-id']]
	);

	const handleTextCopy = () => {
		const copyResult =
			textProps.copyToClipboard?.(tippyContent.text) ||
			navigator.clipboard.writeText(tippyContent.text);

		copyResult
			.then(() => {
				/**
				 * Fixes race condition between snackbar click away listener and Copy button click processing
				 * Required for mobile
				 */
				setTimeout(() => {
					showSnackbar();
					hideTippy();
				});
			})
			.catch(hideTippy);
	};

	const handleClickOutside = (_, event: MouseEvent) => {
		if (event.target !== copyButtonRef.current) {
			hideTippy();
		}
	};

	return (
		<>
			<Tippy
				css={(theme) => WebtextTippyStyles(theme, { platform: textProps.platform })}
				// Add the popover right next to the target to support multiple citations refs
				appendTo={(target) => target}
				interactive
				plugins={[hideOnEscPlugin]}
				content={
					<WebtextTippyContentWrapper
						content={tippyContent.element || tippyContent.text}
						onCopy={handleTextCopy}
						copyButtonRef={copyButtonRef}
						isCitationRef={isCitationRef}
					/>
				}
				visible={isTippyVisible}
				onShow={() => hideAll()}
				onClickOutside={handleClickOutside}
				onHide={hideTippy}
				theme="references"
				arrow={textProps.platform !== 'web'}
				{...(textProps.tippyProps || {})}>
				<TippyTarget
					element={element}
					textProps={textProps}
					isTippyVisible={isTippyVisible}
					onClick={isTippyVisible ? hideTippy : showTippy}
				/>
			</Tippy>
			<Toast
				className="not-annotatable"
				open={snackbarVisible}
				autoHideDuration={6000}
				onClose={hideSnackbar}
				action={
					<Button disableRipple onClick={hideSnackbar}>
						Close
					</Button>
				}>
				Copied!
			</Toast>
		</>
	);
};

/**
 * Using Tippy.js workaround with wrapping elements into <span />
 * https://github.com/atomiks/tippyjs-react#component-children
 */
export const TippyTarget = forwardRef<
	HTMLDivElement,
	{
		isTippyVisible: boolean;
		element: Element;
		textProps: TextProps;
		onClick: () => void;
	}
>((props, ref) => {
	const { isTippyVisible, element, textProps, onClick } = props;

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.code === 'Space' || event.code === 'Enter') {
			event.preventDefault();
			return onClick();
		}
	};

	const processedComponent = domToReact([element], {
		replace: (domNode: Element) => checkForSpecialCase(domNode, textProps)
	});

	return (
		<div css={tippyTargetStyles} ref={ref}>
			{isValidElement(processedComponent) &&
				cloneElement(processedComponent as ReactElement, {
					onKeyDown: handleKeyDown,
					onClick: onClick,
					tabIndex: 0,
					role: 'button',
					'aria-expanded': isTippyVisible
				})}
		</div>
	);
});

const tippyTargetStyles = (theme: Theme) => css`
	display: inline-block;

	a {
		// Overrride core #content [role=button]:not(.bordered-button):not(.ally-ignore) border styles
		border-bottom: 1px dotted ${getThemeItem(theme.colors.link, theme)} !important;

		// Overrride core a:focus dotted outline
		:focus {
			outline: none;
		}
	}

	span[data-source-family-id] {
		// Overrride core #content [role=button]:not(.bordered-button):not(.ally-ignore) border styles
		border-bottom: 1px dotted ${getThemeItem(theme.colors.text, theme)} !important;
	}
`;
TippyTarget.displayName = 'TippyTarget';

/**
 * A reference is required, so we can maintain `onClickOutside` hiding functionality of the tippy
 * and still be able to click the `Copy` button. We check the click target later in this file.
 */
interface WebtextTippyContentWrapperProps {
	content: string | JSX.Element;
	onCopy: () => void;
	copyButtonRef: React.Ref<HTMLButtonElement>;
	isCitationRef?: boolean;
}

const WebtextTippyContentWrapper: React.FC<WebtextTippyContentWrapperProps> = (props) => {
	const { content, copyButtonRef, isCitationRef, onCopy } = props;

	return (
		<div className="reference-container not-annotatable">
			{isCitationRef ? <div className="citation-label">CITATION</div> : null}
			{typeof content === 'string' ? (
				<div
					className="reference-content"
					dangerouslySetInnerHTML={{ __html: checkForBBCode(content) }}
				/>
			) : (
				<div className="reference-content">{content}</div>
			)}
			<div className="reference-copy-container">
				<button ref={copyButtonRef} onClick={onCopy}>
					Copy
				</button>
			</div>
		</div>
	);
};
