import React, { useState } from 'react';

import { withErrorBoundary } from '~/components/GenericErrorBoundary';
import { QuestionPrompt } from '~/components/shared/Question';
import { useAccessibilityFocus } from '~/hooks';

import PollElementWrapper from './PollElementWrapper';
import PollQuestionView from './PollQuestionView';
import { getPollPromptBodyId, getPollQuestionId, studentViewLoadingText } from './utils';

import type { PollProps } from './types';

const PollQuestion: React.FC<PollProps> = (props) => {
	const {
		questionFamilyId,
		body,
		answer,
		online,
		submissionError,
		submitting,
		noBottomMargin,
		tabsUi
	} = props;

	const pollBodyId = getPollPromptBodyId(questionFamilyId);

	const pollQuestionElementId = getPollQuestionId(questionFamilyId);

	const [focusHeadingRef, setFocusToHeading] = useAccessibilityFocus();

	const [userActed, setUserActed] = useState(false);

	const completedAnswer = answer && answer.completed;
	const completedAnswerWithData = completedAnswer && answer.graphData;
	const completedAnswerWithoutData = completedAnswer && !answer.graphData;

	const isLoading = online && !submissionError && (submitting || completedAnswerWithoutData);

	let ariaHeading = null;
	if (userActed) {
		if (isLoading) {
			ariaHeading = studentViewLoadingText;
		} else if (completedAnswerWithData) {
			ariaHeading = 'results displayed below';
		}
	} else {
		ariaHeading = '';
	}

	return (
		<PollElementWrapper
			id={pollQuestionElementId}
			tabsUi={tabsUi}
			noBottomMargin={noBottomMargin}
			ariaHeading={ariaHeading}
			setHeadingRef={focusHeadingRef}>
			<div role="group" aria-labelledby={pollBodyId}>
				<QuestionPrompt id={pollBodyId} body={body} />
				<PollQuestionView
					{...props}
					setUserActed={setUserActed}
					setFocusToHeading={setFocusToHeading}
				/>
			</div>
		</PollElementWrapper>
	);
};

export default withErrorBoundary(PollQuestion);
