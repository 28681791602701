import { ObjectTyped } from 'object-typed';

import constants from './base/constants';
import mixins from './base/mixins';
import blue_velvet from './blue_velvet';
import coral_velvet from './coral_velvet';
import green_velvet from './green_velvet';
import queens_velvet from './queens_velvet';
import strayer_velvet from './strayer_velvet';
import strayer_velvet_2 from './strayer_velvet_2';
import studios_velvet from './studios_velvet';
import texas_politics_velvet from './texas_politics_velvet';
import universal_velvet from './universal_velvet';
import wgu_velvet from './wgu_velvet';
import white_velvet from './white_velvet';

import type { Color, Padding, Theme, ThemeItem, ThemeRef } from './base/types';

// TODO Unify all the themes' types
const themesVariables: Record<ThemeName, unknown> = {
	blue_velvet,
	coral_velvet,
	green_velvet,
	queens_velvet,
	strayer_velvet,
	strayer_velvet_2,
	studios_velvet,
	wgu_velvet,
	white_velvet,
	universal_velvet,
	texas_politics_velvet
};

const resolveThemeReferences = (
	theme: Theme,
	subTheme: Theme | Record<string, ThemeRef<unknown>>
) =>
	Object.keys(subTheme).forEach((key) => {
		const val = subTheme[key];
		switch (typeof val) {
			case 'function':
				subTheme[key] = val(theme);
				break;
			case 'object':
				resolveThemeReferences(theme, val);
				break;
		}
	});

function generateTheme(name: ThemeName, theme: Theme): Theme {
	theme.name = name;
	resolveThemeReferences(theme, theme);
	return theme;
}

const themes = ObjectTyped.entries(themesVariables).reduce(
	(obj, [themeName, themeVariables]) => {
		obj[themeName] = generateTheme(themeName, themeVariables as Theme);
		return obj;
	},
	{} as Record<ThemeName, Theme>
);

const { colors, fonts, fontWeights, breakpoints, breakpointValues } = constants;

export interface ThemeProps {
	theme: Theme;
}
// TODO Re-export all the types
export type { Theme, Padding, Color };

export { mixins, colors, fonts, fontWeights, breakpoints, breakpointValues };

export enum ThemeName {
	BLUE_VELVET = 'blue_velvet',
	CORAL_VELVET = 'coral_velvet',
	GREEN_VELVET = 'green_velvet',
	QUEENS_VELVET = 'queens_velvet',
	STRAYER_VELVET = 'strayer_velvet',
	STRAYER_VELVET_2 = 'strayer_velvet_2',
	STUDIOS_VELVET = 'studios_velvet',
	WGU_VELVET = 'wgu_velvet',
	WHITE_VELVET = 'white_velvet',
	UNIVERSAL_VELVET = 'universal_velvet',
	TEXAS_POLITICS_VELVET = 'texas_politics_velvet'
}

export const getThemeItem = (item: ThemeItem, theme?: Theme): string =>
	typeof item === 'string' ? item : item(theme);

export default themes;
