import React from 'react';

import { withTheme } from '@emotion/react';

import WaveformAudioPlayer, { Props as WaveformAudioPlayerProps } from './WaveformAudioPlayer';

export type WaveformAudioPlayerRef = React.RefObject<WaveformAudioPlayer>;

function WaveformAudioPlayerWithRef(
	props: WaveformAudioPlayerProps,
	ref: React.RefObject<WaveformAudioPlayer>
) {
	return <WaveformAudioPlayer ref={ref} {...props} />;
}

export default withTheme(React.forwardRef(WaveformAudioPlayerWithRef));
