import React, { Component } from 'react';
import { default as ReactPlayer, ReactPlayerProps } from 'react-player';

import brightcovePlayerLoader from '@brightcove/player-loader';

import { Player } from '../types';
import { BrightcovePlayerEvent, BrightcovePlayerObject, BrightcovePlayerConfig } from './types';

const BRIGHTCOVE_REGEX =
	/https:\/\/players.brightcove.net\/(\d+)\/(\w+)(_\w+)\/index.html\?videoId=(\d+)/;

export class BrightcovePlayer extends Component<ReactPlayerProps> implements Player {
	static displayName = 'Brightcove';
	static canPlay(url: string) {
		if (BRIGHTCOVE_REGEX.test(url)) {
			return true;
		}
		return false;
	}

	player: BrightcovePlayerObject = null;
	videoContainer: null | React.RefObject<HTMLDivElement> = null;

	constructor(props: ReactPlayerProps) {
		super(props);
		this.videoContainer = React.createRef<HTMLDivElement>();
	}

	componentDidMount() {
		this.props.onMount && this.props.onMount(this);
	}

	load() {
		const { onReady, url } = this.props;
		const container = this.videoContainer.current;

		const ids = url.toString().match(BRIGHTCOVE_REGEX);

		const accountId = ids[1];
		const playerId = ids[2];
		const videoId = ids[4];

		brightcovePlayerLoader({
			refNode: container,
			refNodeInsert: 'replace',
			accountId,
			playerId,
			videoId,
			embedOptions: {
				responsive: true
			}
		} as BrightcovePlayerConfig)
			.then((success) => {
				this.player = success.ref;
				this.addEventListeners();
				onReady(this as unknown as ReactPlayer);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	addEventListeners() {
		const { onBuffer, onStart, onPlay, onPause, onEnded, onReady } = this.props;
		this.player.on(BrightcovePlayerEvent.LOAD_START, onBuffer);
		this.player.on(BrightcovePlayerEvent.FIRST_PLAY, onStart);
		this.player.on(BrightcovePlayerEvent.PLAY, onPlay);
		this.player.on(BrightcovePlayerEvent.PAUSE, onPause);
		this.player.on(BrightcovePlayerEvent.ENDED, onEnded);
		this.player.on(BrightcovePlayerEvent.LOADED_DATA, onReady);
		this.player.on(BrightcovePlayerEvent.ERROR, console.error);
	}

	seekTo(amount: number) {
		this.player.currentTime(amount);
	}

	getCurrentTime() {
		return this.player.currentTime();
	}

	getDuration() {
		return this.player.duration();
	}

	getSecondsLoaded() {
		return this.player.bufferedPercent() * this.getDuration();
	}

	stop() {
		this.player.dispose();
	}

	render() {
		return <div ref={this.videoContainer} />;
	}
}
