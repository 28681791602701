import React from 'react';

import { GenericErrorBoundary } from '~/components';
import QuestionType from '~/components/shared/Question/QuestionType';
import { VoiceRecordingElement } from '~/types';
import { formatSecondsAgo } from '~/utils/formatting';

import { voiceRecordingStyles } from '.';
import CompletedVoiceRecording from './CompletedVoiceRecording';

export enum CallStatus {
	NotIdentified,
	Identified,
	Completed
}

interface Props {
	voiceRecording: VoiceRecordingElement;
	callStatus: CallStatus;
	recordingUrl: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deleteAnswer: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	submitToLMS: any;
	lmsSupportsFileUpload: boolean;
	secondsSinceUpdate: number;
	errorMessage?: string;
}

class UnboundedVoiceRecording extends React.Component<Props> {
	focusRef = null;

	constructor(props) {
		super(props);
		this.focusRef = React.createRef();
	}

	renderIntro() {
		const {
			voiceRecording,
			voiceRecording: { pin },
			callStatus
		} = this.props;
		if (callStatus === CallStatus.Identified) {
			return (
				<>
					<p>You’re ready to record!</p>
					<p>
						When you’re finished, press pound (#) or simply hang up, and then review your recording
						in the webtext.
					</p>
				</>
			);
		} else {
			let finalInstructions = voiceRecording.instructions.replace(
				/\{pin\}|\{nonce\}/,
				pin
					.toString()
					.padStart(6, '0')
					.replace(/(.{3})/, '$1 ')
					.trim()
			);
			finalInstructions = finalInstructions.replace(
				/\{phone\}/,
				'<a href="tel:' + voiceRecording.phone + 'p' + pin + '">' + voiceRecording.phone + '</a>'
			);
			return <div dangerouslySetInnerHTML={{ __html: finalInstructions }} />;
		}
	}

	saveFinalButtons() {
		const { errorMessage, deleteAnswer, voiceRecording, lmsSupportsFileUpload, submitToLMS } =
			this.props;
		const { autosubmit_button_label, lms_name, autosubmit } = voiceRecording;
		return (
			<>
				{errorMessage && <div className="error">{errorMessage}</div>}
				<div className="save-final">
					{deleteAnswer && <button onClick={deleteAnswer}>Delete</button>}
					{autosubmit && lmsSupportsFileUpload && (
						<button onClick={submitToLMS}>
							{lms_name ? `Submit to ${lms_name}` : autosubmit_button_label || 'Submit for Grading'}
						</button>
					)}
				</div>
			</>
		);
	}

	componentDidUpdate() {
		this.focusRef.current && this.focusRef.current.focus();
	}

	render() {
		let children,
			buttons = null;
		const { secondsSinceUpdate, voiceRecording, callStatus } = this.props;
		let { recordingUrl } = this.props;
		// important for IE - By default Twilio returns a .wav file, but MediaElementPlayer on Windows/IE
		// can't handle that format out of the box. So we request the mp3 resource from Twilio.
		// See https://www.twilio.com/docs/voice/api/recording.
		if (recordingUrl && !recordingUrl.endsWith('.mp3')) {
			recordingUrl = recordingUrl + '.mp3';
		}
		if (recordingUrl && callStatus === CallStatus.Completed) {
			const updatedAgo = secondsSinceUpdate < 1 ? 1 : secondsSinceUpdate;
			const formattedRelativeTime = formatSecondsAgo(updatedAgo);
			children = (
				<div tabIndex={-1} ref={this.focusRef}>
					<CompletedVoiceRecording
						recordingUrl={recordingUrl}
						familyId={voiceRecording.family_id}
					/>
					{formattedRelativeTime && (
						<div className="submissionTime">
							<div>Updated {formattedRelativeTime}</div>
						</div>
					)}
				</div>
			);
			buttons = this.saveFinalButtons();
		} else {
			children = this.renderIntro();
		}
		return (
			<div css={voiceRecordingStyles}>
				<div className="voice-recording-element" id={`voice_recording_${voiceRecording.family_id}`}>
					<QuestionType>Voice Recorder</QuestionType>
					{children}
					{buttons && <div className="controls">{buttons}</div>}
				</div>
			</div>
		);
	}
}

const withErrorBoundary = (Component) => (props) => {
	return (
		<GenericErrorBoundary>
			<Component {...props} />
		</GenericErrorBoundary>
	);
};

export default withErrorBoundary(UnboundedVoiceRecording);
