import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { schemeCategory10 } from 'd3-scale-chromatic';

import { Indeterminate } from '~/components/Loaders';
import { Offline } from '~/components/Offline';
import { useIsUniversalVelvet } from '~/hooks';

import PollResultsPie from './PollResultsPie';
import { RefreshedPollResultsTabs } from './RefreshedPoll';
import { instructorViewLoadingText, studentViewLoadingText, uvPollColors } from './utils';

import type { PollResultsProps } from './types';

interface Props extends PollResultsProps {
	instructorView?: boolean;
}

const PollResultsView: React.FC<Props> = (props) => {
	const {
		answer,
		instructorView = false,
		tabsUi,
		colorPalette: configColorPalette,
		online
	} = props;

	const isUniversalVelvet = useIsUniversalVelvet();

	const loadingText = instructorView ? instructorViewLoadingText : studentViewLoadingText;
	const colorPalette = configColorPalette || (isUniversalVelvet ? uvPollColors : schemeCategory10);

	return (
		<>
			{!answer?.graphData && !online && (
				<Offline elementName="Poll Results" interactionVerb="view" />
			)}
			{!answer?.graphData && online && <PollResultsLoader loadingText={loadingText} />}
			{answer?.graphData && (
				<div>
					{tabsUi ? (
						<RefreshedPollResultsTabs
							{...props}
							classData={answer.graphData}
							colorPalette={colorPalette}
						/>
					) : (
						<PollResultsPie graphData={answer.graphData} colorPalette={colorPalette} />
					)}
				</div>
			)}
		</>
	);
};

const PollResultsLoader: FC<{ loadingText: string }> = ({ loadingText }) => (
	<Box
		display="flex"
		flexDirection="column"
		width="100%"
		height={200}
		alignItems="center"
		justifyContent="center">
		<Indeterminate />
		<Box mt={3}>{loadingText}</Box>
	</Box>
);

export default PollResultsView;
