import { Component } from 'react';
import { type default as ReactPlayer, type ReactPlayerProps } from 'react-player';
import { getSDK } from 'react-player/utils';

import { generateID } from '~/utils';

import { CastLabsStyles } from '.';
import { CastLabsPlayerEvents, type CastLabConfig } from './types';
import { type Player } from '../types';

declare const clpp;
const SDK_CORE_URL = '/assets/castlabs/cl.core.min.js';
const SDK_MSE_URL = '/assets/castlabs/cl.mse.min.js';
const SDK_DASH_URL = '/assets/castlabs/cl.dash.min.js';
const SDK_HLS_URL = '/assets/castlabs/cl.hls.min.js';

type CastLabsPlayerState = {
	config: CastLabConfig;
	playerId: string;
};

export class CastLabsPlayer
	extends Component<ReactPlayerProps, CastLabsPlayerState>
	implements Player
{
	static displayName = 'CastLabs';
	static canPlay(url: string) {
		return url.indexOf('webtext-figure-asset-video-castlabs') > -1;
	}

	player = null;

	constructor(props: ReactPlayerProps) {
		super(props);

		const domParser = new DOMParser();
		const doc = domParser.parseFromString(props.url as string, 'text/html');
		const castLabConfig = JSON.parse(doc.querySelector('video').innerHTML) as CastLabConfig;
		castLabConfig.sources = castLabConfig.sources.map((s) => ({ ...s, url: s.src }));
		if (castLabConfig.subtitles) {
			castLabConfig.subtitles.sources = castLabConfig?.subtitles.sources.map((s) => ({
				...s,
				url: s.src,
				kind: 'subtitles'
			}));
		}

		this.state = {
			config: castLabConfig,
			playerId: `castlabs-${generateID()}`
		};
	}

	componentDidMount() {
		this.props.onMount && this.props.onMount(this);
	}

	load() {
		const { onReady } = this.props;
		[SDK_CORE_URL, SDK_MSE_URL, SDK_DASH_URL, SDK_HLS_URL]
			.reduce((prevPromise, nextURL) => {
				return prevPromise.then(() => {
					return getSDK(nextURL);
				});
			}, Promise.resolve())
			.then((sdks) => {
				this.player = new clpp.Player(this.state.playerId, {
					license: this.state.config.license,
					drm: {
						env: 'DRMtoday',
						customData: {
							userId: this.state.config.drmtoday.userId,
							sessionId: this.state.config.drmtoday.sessionId,
							merchant: this.state.config.drmtoday.merchant
						}
					},
					preferredTextLanguage: 'en',
					enableEnhancedSubtitleManager: true
				});
				this.addEventListeners();
				this.player.use(clpp.dash.DashComponent);
				this.player.use(clpp.hls.HlsComponent);
				this.player.load(this.state.config.sources).then(
					() => {
						this.loadTextTracks().then(() => {
							onReady(this as unknown as ReactPlayer);
						});
					},
					(err) => {
						console.error('CastLabs: could not load video sources: ', err);
					}
				);
			})
			.catch((error) => {
				console.error('Problem getting SDKS for CastLabs -> ', error);
			});
	}

	addEventListeners() {
		const { onBuffer, onPlay, onStart, onPause, onEnded, onReady } = this.props;
		this.player.on(CastLabsPlayerEvents.BUFFERING_STARTED, onBuffer);
		this.player.on(CastLabsPlayerEvents.PLAY, onPlay);
		this.player.on(CastLabsPlayerEvents.PAUSE, onPause);
		this.player.on(CastLabsPlayerEvents.ENDED, onEnded);
		this.player.one(CastLabsPlayerEvents.PLAY, onStart);
		this.player.on(CastLabsPlayerEvents.ERROR, (error) => {
			console.error(error);
			console.log('Could not load source, trying the next one');
			this.player.release();
			if ([...this.player.getSources()].slice(1).length > 0) {
				this.player.load([...this.player.getSources()].slice(1)).then(() => {
					this.loadTextTracks().then(() => {
						onReady(this as unknown as ReactPlayer);
					});
				});
			}
		});
	}

	loadTextTracks() {
		const trackManager = this.player.getTrackManager();
		const textTracks = this.state.config.subtitles.sources;
		return Promise.all(textTracks.map((tt) => trackManager.addTextTrack(tt)))
			.then(() => {
				if (trackManager.getTextTracks()[0]) {
					trackManager.setTextTrack(trackManager.getTextTracks()[0]);
				}
			})
			.catch((err) => {
				console.error('CastLabs: could not fetch subtitles', err);
			});
	}

	seekTo(amount: number) {
		this.player.seek(amount);
	}

	getCurrentTime() {
		return this.player.getPosition();
	}

	getDuration() {
		return this.player.getDuration();
	}

	getSecondsLoaded() {
		const bufferInfo = this.player.getBufferInfo();
		if (this.player) {
			return bufferInfo.a[bufferInfo.a.length - 1].end;
		}
		return null;
	}

	render() {
		return (
			<div css={CastLabsStyles}>
				<video id={this.state.playerId} controls crossOrigin="anonymous"></video>
			</div>
		);
	}
}
