import cloneDeep from 'lodash-es/cloneDeep';
import { darken, lighten } from 'polished';

import { WebtextBlockquote } from '~/components/pageElements';
import { colors } from '~/styles/themes';

import constants from '../base/constants';
import baseTheme from '../blue_velvet';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

const arial = 'Arial, sans-serif';
const futura = "'FuturaStd', sans-serif"; // 100-300=Light 400-500=Book 600=Medium 700-900=Bold
const futuraHeavy = "'FuturaStd-Heavy', sans-serif";

theme.fonts.app = arial;
theme.fonts.webtext = futura;
theme.fonts['webtext-question-body'] = arial;
theme.fonts['webtext-question-answer'] = arial;
theme.fonts['webtext-question'] = arial;
theme.fonts['webtext-question-buttons'] = arial;
theme.fonts['webtext-question-rejoinder-correctness'] = arial;
theme.fonts['webtext-heading-font-family'] = futuraHeavy;
theme.fonts.serif = futura;
theme.fonts['sans-serif'] = futura;

theme.colors.primary = '#00385c';
theme.colors.secondary = '#eaeaea';
theme.colors['dark-gray'] = theme.colors.primary;
theme.colors['gray-high'] = theme.colors.secondary;
theme.colors.accent = theme.colors.primary;
theme.colors.link = (theme) => theme.colors.accent;
theme.colors.burgundy = '#862633';
theme.colors.f7gray = '#f7f7f7';

theme.element.title.fontFamily = futura;
theme.element.title.fontSize = '22px';
theme.element.title.fontWeight = 700;
theme.element.title.margin = '0 0 5px';

theme.webtextPage.title.fontSize = '45px';
theme.webtextPage.title.color = (theme) => theme.colors.accent;
theme.webtextPage.title.textTransform = 'none';
theme.webtextPage.headings.color = (theme) => theme.colors.accent;
theme.webtextPage.h1.fontSize = '40px';
theme.webtextPage.h2.fontSize = '30px';
theme.webtextPage.h3.fontSize = '25px';
theme.webtextPage.h4.fontSize = '22px';
theme.webtextPage.h5.fontSize = '18px';
theme.webtextPage.p.fontSize = '20px';
theme.webtextPage.blockquote.fontFamily = futura;
theme.webtextPage.blockquote.fontWeight = 400;
theme.webtextPage.blockquote.fontStyle = 'italic';

theme.webtextExample.borderColor = theme.colors.burgundy;
theme.webtextCallout.borderColor = theme.colors.burgundy;
theme.webtextCallout.backgroundColor = theme.colors.secondary;

theme.global.webtextPageInstruction.backgroundColor = (theme) => theme.colors.accent;
theme.global.webtextPageInstruction.fontFamily = futura;
theme.global.webtextPageInstruction.fontSize = '20px';
theme.global.webtextPageInstruction.lineHeight = '1.2em';
theme.global.webtextPageInstruction.padding = '22px';

theme.global.webtextGlossaryTermLocations.fontFamily = futura;

theme.global.webtextInvestigationObjective.color = theme.colors.burgundy;
theme.global.webtextInvestigationObjective.fontFamily = futura;
theme.global.webtextObjective.fontFamily = futura;
theme.global.iconColor = theme.colors.burgundy;

theme.global.webtextArticleSource.fontFamily = futura;
theme.global.webtextArticleSource.borderLeft = (theme) => `8px solid ${theme.colors.burgundy}`;

const wguLightBlue = '#4986ad';
theme.bigLink.borderLeft = 'none';
theme.bigLink.hoverBackgroundColor = lighten(0.3, wguLightBlue);
theme.bigLink.icon.fill = (theme) => lighten(0.1, theme.colors.accent);
theme.bigLink.title.color = (theme) => theme.colors.accent;
theme.bigLink.title.fontFamily = futura;
theme.bigLink.title.fontSize = '20px';
theme.bigLink.credit.color = (theme) => theme.colors.accent;
theme.bigLink.credit.fontSize = '17px';
theme.bigLink.credit.aHoverColor = darken(0.05, wguLightBlue);

theme.popupQuiz.colors.primary = (theme) => theme.colors.accent;

theme.waveformAudioPlayer.colors.progress = (theme) => theme.colors.accent;

theme.figure.number.fontFamily = futura;
theme.figure.number.fontSize = '22px';
theme.figure.number.fontWeight = 700;
theme.figure.title.fontFamily = futura;
theme.figure.title.fontSize = '22px';
theme.figure.title.fontWeight = 700;

theme.tableFigure.fontSize = '18px';
theme.tableFigure.headerFontSize = '20px';
theme.tableFigure.strongFontWeight = 'bold';
theme.tableFigure.strongFontSize = '16px';
theme.tableFigure.headerBackground = theme.colors.burgundy;
theme.tableFigure.rowBackground = lighten(0.05, theme.colors.secondary);
theme.tableFigure.rowBackgroundOdd = theme.colors.secondary;

theme.webtextQuestion.questionPrompt.fontFamily = arial;
theme.webtextQuestion.sectionTitle.fontWeight = 'normal';
theme.webtextQuestion.sectionTitle.fontFamily = constants.fonts['helvetica-neue'];
theme.webtextQuestion.rejoinderSeparator.color = theme.colors.secondary;
theme.webtextQuestion.rejoinder.correctness.fontWeight = 600;

theme.artifactLink.icon.color = '#004c88';
theme.artifactLink.title.fontFamily = theme.fonts.webtext;
theme.artifactLink.title.fontSize = '20px';
theme.artifactLink.credits.fontFamily = theme.fonts.webtext;
theme.artifactLink.credits.fontSize = '17px';
theme.artifactLink.transcript.fontFamily = theme.fonts.webtext;
theme.artifactLink.transcript.fontSize = '18px';

theme.endOfChapter.separators.borderColor = theme.colors.burgundy;
theme.endOfChapter.message.color = theme.colors.burgundy;

theme.notifications.fontFamily = theme.fonts.app;

theme.comments.instructor.borderColor = theme.colors.burgundy;
theme.comments.instructor.backgroundColor = `${theme.colors.burgundy}0D`;
theme.comments.instructor.header.title.fontFamily = futura;
theme.comments.instructor.header.title.fontWeight = 700;
theme.comments.instructor.header.title.fontSize = '16px';
theme.comments.instructor.header.title.marginBottom = '3px';
theme.comments.instructor.header.timestamp.fontFamily = futura;
theme.comments.instructor.header.timestamp.fontWeight = 500;
theme.comments.instructor.header.reveal.color = theme.colors.burgundy;
theme.comments.instructor.header.reveal.fontWeight = 700;
theme.comments.instructor.header.reveal.fontFamily = futura;
theme.comments.instructor.comment.fontWeight = 500;
theme.comments.instructor.comment.fontFamily = futura;

theme.tableOfContents.currentItemColor = (theme) => theme.colors.burgundy;
theme.tableOfContents.progressColor = (theme) => theme.colors.burgundy;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}0D`;

theme.webtextPullQuote.heading.color = (theme) => theme.colors.burgundy;
theme.webtextPullQuote.heading.fontFamily = constants.fonts['helvetica-neue'];

theme.webtextBlockquote.borderLeft = (theme) => `6px solid ${theme.colors.burgundy}`;

theme.webtextFigureAssetTable.headerBackground = theme.colors.burgundy;
theme.webtextFigureAssetTable.fontSize = '18px';
theme.webtextFigureAssetTable.headerFontSize = '20px';
theme.webtextFigureAssetTable.rowBackground = theme.colors.f7gray;
theme.webtextFigureAssetTable.oddRowBackground = theme.colors.secondary;

theme.footnote.color = '#6e6e6e';

export default theme;
