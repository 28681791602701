import { css, SerializedStyles } from '@emotion/react';

import { breakpoints } from '~/styles/themes';

const customListItemBullet = (theme) => css`
	color: ${theme.colors.accent};
	font-weight: 900;
	display: inline-block;
	width: 1.5em;
	margin-left: -1.5em;
`;

const customOrderedList = (theme) => css`
	list-style: none;

	> li::before {
		${customListItemBullet(theme)}
		content: counter(list-item) ')';
	}
`;

const customUnorderedList = (theme) => css`
	list-style: disc !important;

	> li {
		&::marker {
			${customListItemBullet(theme)}
			font-size: 1.3em;
			line-height: 0; // Removes excessive line height in Chromium browsers
		}
	}
`;

export const customListItemBullets = (theme) => css`
	--nested-list-margin: 20px;

	@media (max-width: ${breakpoints.small}) {
		--nested-list-margin: 12px;
	}

	.text-component > ol {
		${customOrderedList(theme)}

		ol {
			${customOrderedList(theme)}
			margin-left: var(--nested-list-margin);
		}
		ul {
			${customUnorderedList(theme)}
			margin-left: var(--nested-list-margin);
		}
	}

	.text-component {
		${upperLatinListOverride(css`counter(list-item, upper-latin) ')'`)}
	}

	.text-component > ul {
		${customUnorderedList(theme)}

		ol {
			${customOrderedList(theme)}
			margin-left: var(--nested-list-margin);
		}
		ul {
			${customUnorderedList(theme)}
			margin-left: var(--nested-list-margin);
		}
	}

	ol.webtext-twg-outline {
		list-style-type: none;

		> li::before {
			${customListItemBullet(theme)}
			content: counter(list-item, upper-roman) '.';
		}

		> li > ol {
			list-style-type: none;

			> li::before {
				${customListItemBullet(theme)}
				content: counter(list-item, upper-alpha) '.';
			}

			& > li > ol {
				list-style-type: none;

				> li::before {
					${customListItemBullet(theme)}
					content: counter(list-item, decimal) '.';
				}
			}
		}
	}
`;

export const upperLatinListOverride = (content: SerializedStyles) => css`
	ol[style*='list-style-type: upper-latin'] {
		li {
			&::marker {
				content: '';
			}

			&::before {
				content: ${content};
			}
		}
	}
`;
