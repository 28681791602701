import React, { forwardRef, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { TbArrowsDiagonal } from 'react-icons/tb';
import { ReactPlayerProps } from 'react-player';

import { ClassNames } from '@emotion/react';

import { Offline } from '~/components/Offline/Offline';
import { useToggle } from '~/hooks';
import { useIsUniversalVelvet } from '~/hooks/useIsUniversalVelvet';
import { FigureType, ElementContextData } from '~/types';
import { removeEnlargeLink } from '~/utils/parsing';

import { figureStyles } from '.';
import { BigQuoteEpigraph, TableFigure, VideoFigure, ScriptFigure, AudioFigure } from '..';
import ExpandedCanvasModal from '../ExpandedViewModal/ExpandedCanvasModal';
import ExpandedImageModal from '../ExpandedViewModal/ExpandedImageModal';
import GifImageFigure, { isGifImage } from '../GifImageFigure';
import * as gifImageStyles from '../GifImageFigure/styles';
import IconButton from '../IconButton/IconButton';
import { WaveformAudioPlayerRef } from '../WaveformAudioPlayer';

import type { FigureElement } from '~/types/WebtextManifest';

export interface FigureProps {
	figureOptions: Partial<FigureElement>;
	elementContext?: ElementContextData;
	videoFigureProps?: ReactPlayerProps;
	audioFigureProps?: {
		waveformAudioPlayerRef?: WaveformAudioPlayerRef;
	};
	requireNetwork?: boolean;
	networkAvailable?: boolean;
	mobile?: boolean;
	mock?: boolean;
	expandedViewEnabled?: boolean;
}

const scriptRegExp = new RegExp(`^<script src=['"]([^'"]+)['"].*>$`, 's');
const waveformPlayerRegExp = /waveform-audio-player/;

const Figure = forwardRef<HTMLElement, PropsWithChildren<FigureProps>>((props, ref) => {
	const {
		figureOptions,
		elementContext,
		videoFigureProps,
		audioFigureProps,
		requireNetwork,
		networkAvailable,
		mobile,
		expandedViewEnabled,
		children
	} = props;

	const {
		figure_type,
		figure_number,
		header_style,
		title,
		instructions,
		description,
		captionPrefix,
		caption,
		credits,
		transcript_url,
		payload,
		quote_text,
		quote_author,
		quote_citation
	} = figureOptions;

	const isUV = useIsUniversalVelvet();
	const isGif = useMemo(() => isGifImage(payload), [payload]);
	const [isAnimated, setAnimated] = useState(false);

	const [isPlaying, setPlaying] = useState(
		() => !matchMedia('(prefers-reduced-motion: reduce)').matches
	);

	const [isExpandedImageModalOpen, toggleExpandedImageModalOpen] = useToggle(false);

	const expandButtonRef = useRef<HTMLButtonElement | null>(null);

	const figureLabel = figure_number ? `Figure ${figure_number}` : title || 'figure';
	const expandedViewTitle = [figure_number && `Figure ${figure_number}`, title]
		.filter(Boolean)
		.join(': ');

	const expandedViewCaption = (caption: string) => {
		return expandedViewEnabled ? removeEnlargeLink(caption) : caption;
	};

	const figurePayload = useMemo(() => {
		/* eslint-disable no-fallthrough */
		switch (figure_type) {
			case FigureType.big_quote_epigraph: {
				return (
					<BigQuoteEpigraph text={quote_text} author={quote_author} citation={quote_citation} />
				);
			}
			case FigureType.table: {
				return <TableFigure figure={figureOptions} />;
			}
			case FigureType.audio: {
				if (waveformPlayerRegExp.test(String(payload))) {
					return <AudioFigure figure={figureOptions} audioFigureProps={audioFigureProps} />;
				}
			}
			case FigureType.video: {
				return (
					<VideoFigure
						figure={figureOptions}
						elementContext={elementContext}
						videoFigureProps={videoFigureProps}
						mock={props.mock}
					/>
				);
			}
			case FigureType.image:
				if (isGif) {
					return (
						<GifImageFigure
							figure={figureOptions}
							setAnimated={setAnimated}
							isPlaying={isPlaying}
						/>
					);
				}
			// else, fall through
			default: {
				if (typeof payload === 'string') {
					if (scriptRegExp.test(payload)) {
						const src = scriptRegExp.exec(payload)[1];
						return <ScriptFigure src={src} />;
					} else {
						return <div dangerouslySetInnerHTML={{ __html: payload }} />;
					}
				}
				return payload;
			}
		}
		/* eslint-enable no-fallthrough */
	}, [
		audioFigureProps,
		elementContext,
		figureOptions,
		figure_type,
		isGif,
		isPlaying,
		payload,
		props.mock,
		quote_author,
		quote_citation,
		quote_text,
		videoFigureProps
	]);

	const animatedGifControls = useMemo(
		() =>
			isAnimated ? (
				<>
					{' '}
					<button css={gifImageStyles.playStopToggleButton} onClick={() => setPlaying(!isPlaying)}>
						{isPlaying ? 'Stop' : 'Play'} GIF
						<span className="hidden-accessible">
							GIF is currently {isPlaying ? 'playing' : 'stopped'}
						</span>
					</button>
				</>
			) : null,
		[isAnimated, isPlaying]
	);

	const imageOptionsContainer = (
		<div className="image-options-block">
			<IconButton
				ref={expandButtonRef}
				variant="outlined"
				aria-label="Expand image"
				onClick={toggleExpandedImageModalOpen}>
				<TbArrowsDiagonal aria-hidden="true" /> Expand image
			</IconButton>
		</div>
	);

	const handlePayloadClick = () => {
		if (figure_type === FigureType.image && !isGif) {
			toggleExpandedImageModalOpen();
		}
	};

	return (
		<ClassNames>
			{({ cx }) => (
				<>
					{requireNetwork && !networkAvailable && (
						<Offline
							mobile={mobile}
							elementName={figure_type}
							interactionVerb="stream"
							contentDescription={title}
						/>
					)}
					{(!requireNetwork || networkAvailable) && (
						<figure
							ref={ref}
							css={figureStyles}
							className={cx(
								figureOptions.figure_position,
								!figureOptions.figure_number && 'no-figure-number',
								'webtext-figure'
							)}>
							{figure_number && (
								<h2 className="readable figure-number">
									{figure_type === 'table' ? 'Table' : 'Figure'} {figure_number}
								</h2>
							)}
							<div
								className={cx({
									'figure-interactive-map-title': header_style === 'map',
									hidden: header_style === 'none'
								})}>
								{title && (
									<figcaption
										dangerouslySetInnerHTML={{ __html: title }}
										className={cx('readable', {
											'map-title': header_style === 'map',
											'figure-title': header_style !== 'map'
										})}
									/>
								)}
								{instructions && (
									<div
										className="figure-instructions"
										dangerouslySetInnerHTML={{ __html: instructions }}
									/>
								)}
							</div>
							<div className="figure-payload" id="figure-payload">
								{/* So far we say that "it’s okay if keyboard users cannot tab to the image, since they can tab to the Expand button and use that." */}
								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
								<div onClick={handlePayloadClick}>{figurePayload}</div>
								{children}

								{expandedViewEnabled && figure_type === FigureType.image && imageOptionsContainer}
							</div>
							{description && (
								<div
									className="hidden-accessible"
									dangerouslySetInnerHTML={{ __html: description }}
								/>
							)}
							<div
								className={cx({
									'figure-caption': Boolean(
										captionPrefix || caption || credits || transcript_url || isAnimated
									),
									hidden: !captionPrefix && !caption && !credits && !transcript_url && !isAnimated
								})}>
								{(captionPrefix || caption) && (
									<>
										{figure_type === FigureType.chart && (
											<h3 className="hidden-accessible">Chart caption for {figureLabel}</h3>
										)}
										<p className="figure-caption-body">
											{captionPrefix && (
												<span className="figure-caption-prefix">
													{captionPrefix}
													{caption || (isUV && (credits || transcript_url)) ? ' | ' : ''}
												</span>
											)}

											<span dangerouslySetInnerHTML={{ __html: expandedViewCaption(caption) }} />
											{(!isUV || !credits) && animatedGifControls}
										</p>
									</>
								)}
								{credits && (
									<>
										{figure_type === FigureType.chart && (
											<h3 className="hidden-accessible">Chart credits for {figureLabel}</h3>
										)}
										<p className="figure-caption-credit">
											{isUV && caption && <span>&nbsp;</span>}
											<span dangerouslySetInnerHTML={{ __html: credits }} />{' '}
											{(isUV || !caption) && animatedGifControls}
										</p>
									</>
								)}
								{!credits && !caption && transcript_url && isAnimated && animatedGifControls}
								{transcript_url && (
									<p className="figure-caption-transcript">
										<a
											aria-label={`Read text transcript${
												title ? ` for ${figure_type}: ${title}` : ''
											}`}
											href={transcript_url}>
											Read Text Transcript
										</a>
									</p>
								)}
								{!credits && !caption && !transcript_url && isAnimated && animatedGifControls}
							</div>
						</figure>
					)}
					{expandedViewEnabled && isExpandedImageModalOpen && (
						<>
							{(!isGif || isPlaying) && (
								/**
								 * When it's an image or a playing GIF -
								 * treat them as an `img` element and display in Image Modal.
								 */
								<ExpandedImageModal
									returnFocusRef={expandButtonRef}
									title={expandedViewTitle}
									onClose={toggleExpandedImageModalOpen}
									payload={payload}
								/>
							)}

							{isGif && !isPlaying && (
								/**
								 * When it's a paused GIF - only its first frame is displayed in the `canvas`.
								 * So render it in the Canvas Modal.
								 */
								<ExpandedCanvasModal
									returnFocusRef={expandButtonRef}
									title={expandedViewTitle}
									onClose={toggleExpandedImageModalOpen}
									payload={payload}
								/>
							)}
						</>
					)}
				</>
			)}
		</ClassNames>
	);
});

Figure.displayName = 'Figure';

export default Figure;
