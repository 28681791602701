import React from 'react';

import { css, ClassNames, SerializedStyles } from '@emotion/react';

import { breakpoints, mixins, ThemeName } from '~/styles/themes';
import { QuestionChoice } from '~/types/WebtextManifest';

export interface Props {
	questionFamilyId: string;
	choices: Array<QuestionChoice>;
	disabled?: boolean;
	selectedChoiceFamilyId?: string;
	onChangeSelectedChoice?: (choiceFamilyId: string) => void;
	className?: string;
}

const QuestionChoices: React.FC<Props> = ({
	questionFamilyId,
	choices,
	disabled,
	selectedChoiceFamilyId,
	onChangeSelectedChoice,
	className
}) => (
	<ClassNames>
		{({ cx }) => (
			<div css={questionChoiceStyles} className={cx('question-choices-container', className)}>
				<div className="question-choices" data-disabled={disabled}>
					{choices.map((choice) => (
						<div className="question-choice question-choice-list-item" key={choice.family_id}>
							<input
								type="radio"
								name={questionFamilyId}
								id={`choice_${choice.family_id}`}
								checked={choice.family_id === selectedChoiceFamilyId}
								disabled={disabled}
								value={choice.family_id}
								onChange={(event) => onChangeSelectedChoice?.(event.target.value)}
							/>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label
								htmlFor={`choice_${choice.family_id}`}
								dangerouslySetInnerHTML={{ __html: choice.body }}
							/>
						</div>
					))}
				</div>
			</div>
		)}
	</ClassNames>
);

const questionChoiceStyles = (theme): SerializedStyles => css`
	margin: 0;
	padding: 0;
	border: 0;

	a {
		color: ${theme.colors.link};
		text-decoration: underline;
		font-weight: ${theme.webtextPage.a.fontWeight};
	}

	.question-choices {
		margin: 0;
		background-color: ${theme.colors.white};
		padding: 10px 5px 5px 0;

		@media (max-width: ${breakpoints.small}) {
			padding-right: 0;
		}

		.question-choice {
			// Radio inner bullet is emulated!
			// Having a dedicated pseudoelement produces inconsistent positioning across browsers
			// See https://soomo.height.app/T-91398#2c2b0628-fb63-42b3-97c0-bfe54dfb0ec6
			//
			// Instead, input has a background color which is initially entirely covered by a border, 1/2 of the input's width.
			// On selection the border width reduces to 1/5, revealing the background color. It looks like a bullet!
			--radio-button-size: 1em;

			display: grid;
			grid-template-columns: var(--radio-button-size) auto;
			gap: 0.1em;

			margin: 0.3em 0.5em 0.3em 0.8em;
			font-size: ${theme.webtextQuestion.fontSize};

			@media (max-width: ${breakpoints.small}) {
				margin-right: 0;
			}

			line-height: 1.6em;

			input[type='radio'] {
				appearance: none; // Required to override browser's styles (https://mzl.la/44TzFsU)
				margin: 0; // Not removed via appearance

				position: relative;
				top: 0.31em; // Aligns with the text baseline

				font: inherit;
				color: currentColor;
				width: var(--radio-button-size);
				height: var(--radio-button-size);

				outline: 1px solid ${theme.colors['medium-gray']};

				// Intentionally using 1.9 as workaround for a weird Safari width rounding
				// Otherwise, background is not entirely covered
				// See https://ibb.co/YcK2Xxs
				border: calc(var(--radio-button-size) / 1.9) solid ${theme.colors.white};
				border-radius: 50%;
				background-color: ${theme.colors.primary};

				transition: border-width 110ms ease-in-out;

				&:checked {
					outline-color: ${theme.colors.primary};
					border-width: calc(var(--radio-button-size) / 5);
				}

				&:disabled {
					opacity: 0.5;
					outline-color: ${theme.colors['medium-gray']};
					background-color: ${theme.colors['medium-gray']};
				}

				&:focus-visible + label {
					${mixins.webtextAccessibleFocused(theme)}
				}
			}

			label {
				display: block;
				padding: 0 0.5em;
				border: 1px solid transparent;
				line-height: inherit;

				@media (max-width: ${breakpoints.small}) {
					padding: 0 0 0 0.5em;
				}
			}
		}
	}

	${theme.name === ThemeName.UNIVERSAL_VELVET && universalVelvetOverrides}
`;

const universalVelvetOverrides = css`
	padding-top: 0;

	.question-choices {
		padding-bottom: 1em;
		background: none;

		.question-choice {
			--radio-button-size: 1.24em;

			gap: 0.5em;
			margin-bottom: 1em;
			margin-left: 0;

			font-size: 18px;
			line-height: 22px;

			input[type='radio'] {
				top: 0.13em; // Aligns with the text baseline
			}
		}

		@media (max-width: ${breakpoints.small}) {
			margin-bottom: 0;
			padding-bottom: 13px;
			padding-top: 0; // QuestionPrompt already has enough margin after itself
			border-bottom: none;

			.question-choice {
				font-size: 15px;
			}
		}
	}
`;

export default QuestionChoices;
