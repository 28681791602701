import React, { FC, useMemo, useRef } from 'react';
import { MdOutlineInfo } from 'react-icons/md';
import { TbArrowsDiagonal } from 'react-icons/tb';

import { cx } from '@emotion/css';
import { Global } from '@emotion/react';
import Tab from '@mui/base/Tab';
import TabPanel from '@mui/base/TabPanel';
import Tabs from '@mui/base/Tabs';
import TabsList from '@mui/base/TabsList';
import { map, sum } from 'lodash-es';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

import { WebtextButton } from '~/components';
import AlternateViewButton from '~/components/pageElements/ChartFigure/AlternateViewButton';
import { ExpandedViewModal } from '~/components/pageElements/ExpandedViewModal';
import DownloadCSVLink from '~/components/pageElements/PollQuestion/DownloadCSVLink';
import { pollLabelTippyStyles } from '~/components/pageElements/PollQuestion/styles';
import { useToggle } from '~/hooks';
import { PollClassData } from '~/types/WebtextManifest';

import { PollProps, RefreshPollProps } from '../types';
import RefreshedPollResultsBars from './ResultsBars';
import RefreshedPollResultsDataTable from './ResultsDataTable';
import RefreshedPollResultsPie from './ResultsPie';
import IconButton from '../../IconButton/IconButton';

export interface Props
	extends Pick<PollProps, 'body' | 'choices' | 'questionFamilyId' | 'onTabChange'>,
		RefreshPollProps {
	classData: PollClassData;
	instructorView?: boolean;
}

const RefreshedPollResultsTabs: FC<Props> = (props) => {
	const {
		questionFamilyId,
		courseNumber = '',
		body,
		choices,
		instructions,
		caption,
		credits,
		classData,
		externalData,
		totalCount,
		totalNumberOfStudents,
		dataType,
		dataGroupBy,
		choiceOrdering,
		sourceDatasetIndex,
		colorPalette,
		colorGroupBy,
		chartType: propsChartType,
		contextualInfo,
		instructorView,
		description,
		onFileDownload,
		onTabChange,
		tippyProps,
		expandedViewEnabled
	} = props;

	const chartType = propsChartType || 'pie'; // set the default chartType to 'pie' in case chartType wasn't selected

	const isClassOnly = !externalData;

	const [isHighContrast, toggleHighContrast] = useToggle(false);
	const [isExpandedViewModalOpen, toggleExpandedViewModalOpen] = useToggle(false);

	const expandButtonRef = useRef<HTMLButtonElement | null>(null);
	const expandedViewChartRef = useRef<HTMLElement | null>(null);
	const pollChartRect = expandedViewChartRef.current?.getBoundingClientRect();

	const isGraphDataEmpty = useMemo(() => sum(map(classData, 'data')) === 0, [classData]);

	const captionAndCredits =
		caption || credits ? (
			<div className="poll-caption-and-credits">
				<span className="poll-caption" dangerouslySetInnerHTML={{ __html: caption }} />
				{!!credits && (
					<>
						{' '}
						<span className="poll-credits" dangerouslySetInnerHTML={{ __html: credits }} />
					</>
				)}
			</div>
		) : null;

	const contrastToggleButton =
		!expandedViewEnabled && chartType === 'pie' && !isGraphDataEmpty ? (
			<span className="contrast-toggle-container">
				<WebtextButton variant="text" className="contrast-toggle" onClick={toggleHighContrast}>
					Show {isHighContrast ? 'default chart view' : 'alternate chart view'}
				</WebtextButton>
				{captionAndCredits ? ' | ' : ''}
			</span>
		) : null;

	const optionsContainer = (
		<div className="poll-chart-options">
			<IconButton
				ref={expandButtonRef}
				variant="outlined"
				aria-label="Expand chart"
				onClick={toggleExpandedViewModalOpen}>
				<TbArrowsDiagonal aria-hidden="true" /> Expand chart
			</IconButton>
			{expandedViewEnabled && chartType === 'pie' && (
				<AlternateViewButton
					isHighContrast={isHighContrast}
					toggleHighContrast={toggleHighContrast}
				/>
			)}
		</div>
	);

	const renderChart = (options: { isExpandedView: boolean }) => {
		switch (chartType) {
			case 'bar':
				return (
					<RefreshedPollResultsBars
						questionFamilyId={questionFamilyId}
						courseNumber={courseNumber}
						choices={choices}
						classData={classData}
						externalData={externalData}
						totalCount={totalCount}
						dataType={dataType}
						dataGroupBy={dataGroupBy}
						choiceOrdering={choiceOrdering}
						sourceDatasetIndex={sourceDatasetIndex}
						colorPalette={colorPalette}
						colorGroupBy={colorGroupBy}
						chartType={chartType}
						description={description}
						tippyProps={tippyProps}
						{...(!options.isExpandedView && { ref: expandedViewChartRef })}
					/>
				);
			default:
				return (
					<RefreshedPollResultsPie
						questionFamilyId={questionFamilyId}
						classData={classData}
						colorPalette={colorPalette}
						choices={choices}
						choiceOrdering={choiceOrdering}
						isHighContrast={isHighContrast}
						description={description}
						tippyProps={tippyProps}
						{...(!options.isExpandedView && { ref: expandedViewChartRef })}
					/>
				);
		}
	};

	return (
		<>
			<Global styles={pollLabelTippyStyles} />
			<Tabs
				defaultValue="chart"
				className="poll-tabs"
				onChange={(_, value: string) => onTabChange?.(value)}>
				<TabsList aria-label="Poll question tabs">
					<Tab value="chart">Chart</Tab>
					<Tab value="data">Data</Tab>
				</TabsList>
				<TabPanel value="chart">
					<div className="poll-data-header">
						<div className="poll-title poll-title-large">Poll Responses</div>
						{!!instructions && <div className="poll-chart-instructions">{instructions}</div>}
						{instructorView && isGraphDataEmpty && <EmptyAnswersWarning tab="chart" />}
						{chartType === 'pie' && isClassOnly && !isGraphDataEmpty && (
							<CourseNumberTitle courseNumber={courseNumber} />
						)}
					</div>
					{renderChart({ isExpandedView: false })}
					{expandedViewEnabled && optionsContainer}
					<div className="poll-caption-credits-container">
						{contrastToggleButton}
						{captionAndCredits}
					</div>
				</TabPanel>
				<TabPanel value="data" className="poll-data-tab">
					<div className="poll-data-header poll-data-header-data-tab">
						{isClassOnly && <CourseNumberTitle courseNumber={courseNumber} />}

						<DownloadCSVLink
							classData={classData}
							choices={choices}
							externalData={externalData}
							totalCount={totalCount}
							dataType={dataType}
							onFileDownload={onFileDownload}
							choiceOrdering={choiceOrdering}
							sourceDatasetIndex={sourceDatasetIndex}
						/>
					</div>
					<RefreshedPollResultsDataTable
						courseNumber={courseNumber}
						choices={choices}
						classData={classData}
						externalData={externalData}
						totalCount={totalCount}
						totalNumberOfStudents={totalNumberOfStudents}
						dataType={dataType}
						choiceOrdering={choiceOrdering}
						sourceDatasetIndex={sourceDatasetIndex}
						contextualInfo={contextualInfo}
					/>
					<div className="poll-caption-credits-container">{captionAndCredits}</div>
				</TabPanel>
			</Tabs>
			{expandedViewEnabled && isExpandedViewModalOpen && pollChartRect && (
				<ExpandedViewModal<HTMLDivElement>
					returnFocusRef={expandButtonRef}
					title={body}
					ariaLabel="Expanded view of poll results"
					onClose={toggleExpandedViewModalOpen}>
					{({ elementRef }) => (
						<div
							ref={elementRef}
							style={{ width: pollChartRect.width, height: pollChartRect.height }}>
							{renderChart({ isExpandedView: true })}
						</div>
					)}
				</ExpandedViewModal>
			)}
		</>
	);
};

const EmptyAnswersWarning: FC<{ tab: 'chart' | 'data' }> = ({ tab }) => (
	<div className="poll-chart-empty-warning">
		<MdOutlineInfo size={22} />
		<span>
			This {tab === 'chart' ? 'chart' : 'table'} will update as students respond to the poll.
		</span>
	</div>
);

const CourseNumberTitle: FC<{ courseNumber: string }> = ({ courseNumber }) => (
	<div className="poll-title poll-title-small">
		<>
			<div>Your Class</div>
			<div className="course-number">{courseNumber}</div>
		</>
	</div>
);

export default RefreshedPollResultsTabs;
