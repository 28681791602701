import cloneDeep from 'lodash-es/cloneDeep';
import { lighten } from 'polished';

import constants from '../base/constants';
import baseTheme from '../blue_velvet';

import type { Theme } from '..';

const theme = cloneDeep(baseTheme) as any; // TODO Unify types! Previously untyped `lodash.clonedeep` was used.

theme.layout.contentColumnWidth = '800px';
theme.colors.primary = '#5f01df';
theme.colors.accent = '#cccccc';
theme.colors.text = '#000000';
theme.colors.link = theme.colors.primary;

const fonts: Theme['fonts'] = {
	...baseTheme.fonts,
	webtext: constants.fonts['publico'],
	'webtext-body': constants.fonts['publico'],
	app: constants.fonts['haas-grotesk-all'],
	header: constants.fonts['haas-grotesk-all'],
	'webtext-heading-font-family': constants.fonts['haas-grotesk-all'],
	serif: constants.fonts.publico,
	'sans-serif': constants.fonts['haas-grotesk'],
	'webtext-question-body': constants.fonts['haas-grotesk-medium'],
	'webtext-question-rejoinder': constants.fonts['haas-grotesk-all'],
	'webtext-question-rejoinder-correctness': constants.fonts['haas-grotesk-all'],
	'webtext-question-answer': constants.fonts['haas-grotesk'],
	'webtext-question': constants.fonts['haas-grotesk'],
	'webtext-question-buttons': constants.fonts['haas-grotesk']
};

theme.chapterSplash.title.number = {
	fontFamily: fonts['sans-serif'],
	fontWeight: constants.fontWeights.light,
	fontSize: '12px',
	textTransform: 'uppercase',
	letterSpacing: '3px'
};
theme.chapterSplash.title.name = {
	fontFamily: constants.fonts['haas-grotesk-display'],
	fontWeight: constants.fontWeights.bold,
	fontSize: '29px'
};
theme.chapterSplash.credit = {
	color: theme.colors['pure-white'],
	fontStyle: 'normal',
	fontSize: '10px'
};

theme.webtextPage.title = {
	color: 'black',
	fontSize: '56px'
};
theme.webtextPage.blockquote.fontStyle = 'normal';

const popupQuiz = {
	...theme.popupQuiz,
	colors: {
		primary: theme.colors.primary,
		secondary: '#ece9e9',
		warning: '#ff3020'
	},
	fontFamily: fonts.app,
	view: {
		fontFamily: fonts.app,
		fontSize: '15px'
	},
	heading: {
		fontFamily: fonts.app,
		fontWeight: constants.fontWeights.regular
	},
	header: {
		fontFamily: fonts.app,
		fontWeight: constants.fontWeights.regular
	},
	instructions: {
		fontWeight: constants.fontWeights.regular
	},
	progress: {
		completedBackground: theme.colors.primary,
		incompleteBackground: '#d3d3d3',
		indicatorRadius: '8px',
		indicatorHeight: '15px',
		description: {
			fontSize: '14px',
			fontFamily: constants.fonts['haas-grotesk']
		}
	},
	choice: {
		background: 'white'
	},
	choiceSpanSelected: {
		background: theme.colors.primary
	},
	choiceSelected: {
		background: lighten(0.5, theme.colors.primary)
	},
	questionBody: {
		fontFamily: constants.fonts['haas-grotesk-bold'],
		fontWeight: constants.fontWeights.regular
	},
	questionPrompt: {
		fontSize: '24px',
		fontWeight: constants.fontWeights['semi-bold'],
		fontFamily: constants.fonts['haas-grotesk-all'],
		mobile: {
			fontSize: '18px'
		}
	},
	divider: {
		height: '1px',
		backgroundColor: theme.colors.accent
	}
};

theme.popupQuiz = popupQuiz;
theme.fonts = fonts;
theme.webtextQuestion.backgroundColor = '#f5f5f5';
theme.webtextQuestion.rejoinder = {
	correct: {
		color: '#007e0c'
	},
	incorrect: {
		color: '#d40101'
	},
	correctInfo: {
		color: 'inherit'
	},
	correctness: {
		fontWeight: constants.fontWeights.medium,
		fontFamily: constants.fonts['haas-grotesk-all']
	}
};
theme.webtextQuestion.answerInput.margin = '0 0 12px';
theme.webtextQuestion.answerInput.invalid.border = '1px solid #E41B0C';
theme.webtextQuestion.questionPrompt.fontSize = '18px';
theme.webtextQuestion.questionPrompt.lineHeight = '30px';
theme.webtextQuestion.questionPrompt.fontWeight = constants.fontWeights.medium;
theme.webtextQuestion.questionPrompt.color = '#000';
theme.webtextQuestionButtons.fontWeight = constants.fontWeights.medium;
theme.webtextQuestion.questionType.fontSize = '14px';
theme.webtextQuestion.validationStatus.fontWeight = constants.fontWeights.medium;
theme.webtextQuestion.validationStatus.fontSize = '16px';

theme.primarySource = {
	sourceAttribute: {
		fontFamily: constants.fonts['haas-grotesk-all'],
		fontWeight: constants.fontWeights.medium
	},
	sourceTitle: {
		fontFamily: constants.fonts['haas-grotesk-bold']
	},
	separator: {
		color: '#5a5a5a',
		fontFamily: constants.fonts['haas-grotesk-italic'],
		borderBottom: '2px dashed #c9c9c9'
	},
	sourceCredit: {
		fontFamily: constants.fonts['haas-grotesk-italic'],
		fontSize: '16px',
		color: '#6d6e6e',
		em: {
			fontFamily: constants.fonts['haas-grotesk']
		}
	},
	sourceContent: {
		fontFamily: constants.fonts['haas-grotesk']
	}
};

theme.webtextCallout = {
	borderWidth: `1px`,
	borderRadius: `10px`,
	backgroundColor: `#ffffff`,
	padding: `50px`,
	fontFamily: fonts.serif
};

theme.webtextExample = {
	...theme.webtextExample,
	borderColor: '#afafaf'
};

theme.webtextPullQuote.heading = {
	fontSize: '16px',
	fontFamily: fonts.app,
	fontWeight: 'normal',
	color: theme.colors.primary
};

theme.artifactLink.title.fontFamily = fonts['webtext-question-body'];
theme.artifactLink.backgroundColor = theme.colors.white;
theme.artifactLink.padding = '30px 30px 30px 0';
theme.artifactLink.credits.fontFamily = fonts['webtext-question-rejoinder'];
theme.artifactLink.credits.color = theme.colors.black;
theme.artifactLink.credits.fontSize = '16px';
theme.artifactLink.transcript.fontSize = '16px';
theme.artifactLink.transcript.fontFamily = fonts['webtext-question-body'];
theme.artifactLink.transcript.color = theme.colors.black;
theme.artifactLink.title.fontSize = '20px';
theme.artifactLink.title.color = theme.colors.primary;
theme.artifactLink.title.textDecorationColor = theme.colors.primary;

theme.webtextWritingTemplate.instructions = {
	background: '#585858'
};
theme.webtextWritingTemplate.fontSize = '18px';
theme.webtextWritingTemplate.fontFamily = constants.fonts['haas-grotesk-display'];
theme.webtextWritingTemplate.title = {
	marginBottom: '22px',
	display: 'inline-block',
	fontSize: '24px',
	fontWeight: constants.fontWeights.regular
};
theme.webtextWritingTemplate.icon = {
	height: '28px',
	width: '28px'
};

theme.figure = {
	...theme.figure,
	number: {
		...theme.figure.number,
		fontFamily: theme.fonts.app,
		fontSize: '16px',
		fontWeight: 'bold',
		lineHeight: '1.2em',
		letterSpacing: '2.8px',
		textTransform: 'uppercase'
	},
	title: {
		...theme.figure.title,
		fontFamily: theme.fonts.header,
		fontSize: '24px',
		lineHeight: '30px',
		marginBottom: '25px'
	},
	caption: {
		...theme.figure.caption,
		fontSize: '14px',
		fontFamily: theme.fonts.app,
		marginBottom: '28px',
		lineHeight: '20px',
		body: {
			fontSize: '14px',
			display: 'inline'
		},
		transcript: {
			fontSize: '14px',
			marginTop: '15px'
		}
	},
	payload: {
		assetImage: {
			paddingBottom: '0px'
		}
	}
};

theme.textToSpeech = {
	color: '#535252',
	fontSize: '16px',
	iconButton: {
		color: '#535252',
		fontSize: '14px',
		border: '1px solid #d2d2d2'
	},
	selected: {
		border: `2px solid ${theme.colors.primary}`
	},
	noHighlightColor: '#000000'
};

theme.voiceRecording = {
	colors: {
		primary: (theme) => theme.colors.primary,
		secondary: (theme) => theme.colors.secondary,
		warning: (theme) => theme.colors.warning
	},
	fontFamily: (theme) => theme.fonts.app,
	fontSize: '18px',
	heading: {
		fontFamily: constants.fonts['helvetica-neue-light'],
		fontWeight: constants.fontWeights.light
	}
};

theme.timeline = {
	fontFamily: fonts.app,
	heading: {
		fontFamily: fonts.header,
		fontSize: '24px',
		lineHeight: '30px'
	},
	media: {
		fontFamily: fonts.app
	},
	slidenav: {
		fontFamily: fonts.app
	},
	date: {
		fontFamily: fonts.app
	},
	slider: {
		fontFamily: fonts.app
	}
};

theme.endOfChapter = {
	...theme.endOfChapter,
	separators: {
		...theme.endOfChapter.separators,
		borderColor: '#000000',
		borderBottom: false
	},
	message: {
		...theme.endOfChapter.message,
		padding: '30px 36px',
		margin: '20px 0 35px 0',
		borderRadius: '8px',
		color: theme.colors.text,
		backgroundColor: '#f5f5f5'
	}
};

theme.notifications = {
	...theme.notifications,
	headerHeight: '52px',
	headerHeightMobile: '44px',
	fontSize: '16px',
	fontWeight: constants.fontWeights.medium,
	fontFamily: fonts.app,

	button: {
		...theme.notifications.button,
		padding: '7px 10px',
		textColor: theme.colors.text,
		textTransform: 'capitalize',
		dotBackgroundColor: '#CE0000',

		unread: {
			...theme.notifications.button.unread,
			backgroundColor: theme.colors.primary,
			textColor: '#ffffff',
			counterColor: '#000000'
		},
		active: {
			...theme.notifications.button.active,
			backgroundColor: theme.colors.primary,
			textColor: '#ffffff',
			counterColor: '#000000',
			dotBorderColor: '#ffffff'
		},
		hover: {
			...theme.notifications.button.hover,
			backgroundColor: theme.colors.primary,
			textColor: '#ffffff',
			counterColor: '#000000',
			dotBorderColor: '#ffffff'
		}
	},

	panel: {
		...theme.notifications.panel,
		width: '299px',
		backgroundColor: '#ECE9E9',
		mainTextColor: '#000000',
		secondaryTextColor: '#585858',

		nav: {
			...theme.notifications.panel.nav,
			textColor: '#585858',
			textColorActive: '#585858'
		},
		select: {
			...theme.notifications.panel.select,
			textColor: '#585858',
			backgroundColor: '#ffffff'
		},
		element: {
			...theme.notifications.panel.element,
			backgroundColor: '#ffffff',
			backgroundColorHover: '#ECE9E9',
			borderColor: '#CBCBCB'
		}
	}
};

theme.comments.instructor.topBorderRadius = '8px';
theme.comments.instructor.bottomBorderRadius = '8px';
theme.comments.instructor.color = '#fff';
theme.comments.instructor.borderColor = '#5f01df';
theme.comments.instructor.backgroundColor = '#5f01df';
theme.comments.instructor.padding = '23px 50px 22px 65px';
theme.comments.instructor.paddingOpen = '23px 50px 22px 63px';
theme.comments.instructor.header.title.fontSize = '16px';
theme.comments.instructor.header.title.fontFamily = constants.fonts['haas-grotesk-all'];
theme.comments.instructor.header.bellIcon.fontSize = '22px';
theme.comments.instructor.header.bellIcon.color = '#CE0000';
theme.comments.instructor.header.reveal.fontSize = '18px';
theme.comments.instructor.header.reveal.color = '#fff';
theme.comments.instructor.header.reveal.extraSmallPaddingLeft = '42px';
theme.comments.instructor.header.reveal.arrow.top = '0.2em';
theme.comments.instructor.comment.fontSize = '16px';
theme.comments.instructor.comment.lineHeight = '1.526';
theme.comments.instructor.comment.fontFamily = theme.fonts.app;

theme.tableOfContents.currentItemColor = (theme) => theme.colors.primary;
theme.tableOfContents.progressColor = (theme) => theme.colors.primary;

theme.outlineTemplate.addButton.fontWeight = 500;

theme.pollQuestion.cellBackgroundColor = (theme) => `${theme.colors.primary}0D`;

export default theme;
