import React, { useLayoutEffect, useMemo, useState } from 'react';

import { cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { isNil } from 'lodash-es';

import { tableContainerStyles, tableResponsiveStyles } from './styles';

import type { FigureElement } from '~/types/WebtextManifest';

interface Props {
	figure: Partial<FigureElement>;
}

const TableFigure: React.FC<Props> = (props) => {
	const { figure } = props;

	const theme = useTheme();

	const tablePayload = String(figure.payload);

	const tableDoc = useMemo(() => {
		const domParser = new DOMParser();
		return domParser.parseFromString(tablePayload, 'text/html');
	}, [tablePayload]);

	const [tableContainer, setTableContainer] = useState<HTMLDivElement | null>(null);

	/**
	 * Makes the table take `contentColumnWidth`
	 * for the narrow mobile screens.
	 * That way columns will be distributed against
	 * that width and won't be too narrow.
	 *
	 * It's applied only for tables with more than two columns
	 * to prevent redundant scrolling on small screens.
	 */
	useLayoutEffect(() => {
		const tablesElements = Array.from(tableDoc.getElementsByTagName('table'));

		tablesElements.forEach((table) => {
			const columns = Array.from(table.getElementsByTagName('col'));
			const columnsWithExplicitWidth = columns
				.map((colElement) => colElement.getAttribute('width'))
				.filter((width) => !isNil(width));

			const isAllColumnsEqual = columnsWithExplicitWidth.length === 0;

			const smallTableColumnsNumber = 2;
			const shouldExpandTable = getColumnsNumber(table) > smallTableColumnsNumber;

			const tableClass = table.getAttribute('class');
			table.setAttribute(
				'class',
				cx(
					tableClass,
					tableResponsiveStyles(theme, {
						isEqual: isAllColumnsEqual,
						expandNarrowTable: shouldExpandTable
					})
				)
			);
		});

		const tableContainer = document.createElement('div');
		tableContainer.classList.add('table-figure-container');

		tableContainer.innerHTML = tableDoc.body.innerHTML;
		setTableContainer(tableContainer);

		return () => setTableContainer(null);
	}, [tableDoc, tablePayload, theme]);

	return tableContainer ? (
		<div
			css={tableContainerStyles}
			dangerouslySetInnerHTML={{ __html: tableContainer.outerHTML }}
		/>
	) : null;
};

const getColumnsNumber = (table: HTMLTableElement): number => {
	const colsElement = Array.from(table.getElementsByTagName('col'));
	if (colsElement.length > 0) {
		return colsElement.length;
	}

	const rowsColumnsAmounts = Array.from(table.getElementsByTagName('tr')).map(
		(rowElement) => rowElement.childElementCount
	);
	return Math.max(...rowsColumnsAmounts);
};

export default TableFigure;
