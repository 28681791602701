import React, { Component } from 'react';
import { default as ReactPlayer, ReactPlayerProps } from 'react-player';
import { getSDK } from 'react-player/utils';

import { generateID, getQueryParams } from '~/utils';

import { KalturaStyles } from '.';
import { Player } from '../types';
import {
	IkWidget,
	KalturaNotificationName,
	KalturaPlayerElement,
	KalturaPlayerEvents
} from './types';

type KalturaPlayerState = {
	targetId: string;
};

export class Kaltura extends Component<ReactPlayerProps, KalturaPlayerState> implements Player {
	static displayName = 'Kaltura';
	static canPlay(url: string): boolean {
		const partnerId = url.match(/(?:partner_id\/)(\d+)/)?.[1];
		const uiConfId = url.match(/(?:uiconf_id\/)(\d+)/)?.[1];
		const entryId = url.match(/(?:entry_id[/|=])([^/&]+)/)?.[1];
		return url.includes('kaltura.com') && !!(partnerId && uiConfId && entryId);
	}

	player: KalturaPlayerElement = null;
	playKitPlayer: any = null;

	constructor(props: ReactPlayerProps) {
		super(props);

		this.state = {
			targetId: `kaltura-${generateID()}`
		};
	}

	componentDidMount() {
		return this.props.onMount && this.props.onMount(this);
	}

	load() {
		const { url, onReady } = this.props;
		const partnerId = url.toString().match(/(?:partner_id\/)(\d+)/)[1];
		const uiConfId = url.toString().match(/(?:uiconf_id\/)(\d+)/)[1];
		const entryId = url.toString().match(/(?:entry_id[/|=])([^/&]+)/)[1];

		getSDK(`https://cdnapi.kaltura.com/p/${partnerId}/embedPlaykitJs/uiconf_id/${uiConfId}`)
			.then(() => {
				const kalturaPlayers = [];
				try {
					const player = (window as any).KalturaPlayer.setup({
						targetId: this.state.targetId,
						provider: {
							partnerId: partnerId,
							uiConfId: uiConfId
						}
					});
					this.playKitPlayer = player;
					kalturaPlayers[entryId] = player;
					player.loadMedia({ entryId });
					player.ready().then(() => {
						this.addEventListeners();
						onReady(this as unknown as ReactPlayer);
					});
				} catch (e) {
					(window.Rollbar || console).error(e.message);
				}
			})
			.catch(() => {
				// We originally requested a javascript client for the Playkit player, because
				// this is what we were told Strayer is switching to. However, the Playkit player
				// has more advanced features and requires a different configuration. Fortunately,
				// Kaltura looks at the configuration you pass along with the request and returns
				// an error if it's not compatible with the player. If this happens, we'll fall back
				// to embedding the video using an iframe, which is the approach that works for the
				// original configuration we were given.
				const urlParams = getQueryParams(url);
				/**
				 * "flashvars" should appear at the end of the url provided to this player.
				 * Example: &flashvars[mediaProxy.mediaPlayFrom]=10
				 */
				const FLASHVARS_REGEX = /(?:flashvars\[([\w.]*)\])/;
				const flashvars = Object.keys(urlParams)
					.filter((key) => key.match(FLASHVARS_REGEX))
					.reduce(
						(acc, key) => ({
							...acc,
							[key.match(FLASHVARS_REGEX)[1]]: urlParams[key]
						}),
						{}
					);

				getSDK(
					`https://cdnapi.kaltura.com/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiConfId}/partner_id/${partnerId}`,
					'kWidget'
				)
					.then((kWidget: IkWidget) => {
						kWidget.embed(this.state.targetId, {
							wid: `_${partnerId}`,
							uiconf_id: uiConfId,
							entry_id: entryId,
							flashvars: {
								...flashvars,
								'EmbedPlayer.EnableIpadNativeFullscreen': true,
								'EmbedPlayer.WebKitPlaysInline': true
							},
							params: {},
							readyCallback: () => {
								const kdp = document.getElementById(this.state.targetId) as KalturaPlayerElement;
								kdp.kBind(KalturaPlayerEvents.MEDIA_READY, () => {
									this.player = kdp;
									this.addEventListeners();
									onReady(this as unknown as ReactPlayer);
								});
							}
						});
					})
					.catch((error) => {
						(window.Rollbar || console).error(error);
					});
			});
	}

	addEventListeners() {
		const { onBuffer, onPlay, onPause, onEnded } = this.props;
		if (this.playKitPlayer) {
			this.playKitPlayer.addEventListener(this.playKitPlayer.Event.Core.PLAY, onPlay);
			this.playKitPlayer.addEventListener(this.playKitPlayer.Event.Core.ENDED, onEnded);
			this.playKitPlayer.addEventListener(this.playKitPlayer.Event.Core.PAUSE, onPause);
			this.playKitPlayer.addEventListener(this.playKitPlayer.Event.Core.CAN_PLAY, onBuffer);
			this.playKitPlayer.addEventListener(this.playKitPlayer.Event.Core.ERROR, (event) => {
				(window.Rollbar || console).error(event.payload);
			});
		} else {
			this.player.kBind(KalturaPlayerEvents.PLAYER_PLAY_END, onEnded);
			this.player.kBind(KalturaPlayerEvents.PLAYER_PLAYED, onPlay);
			this.player.kBind(KalturaPlayerEvents.PLAYER_PAUSED, onPause);
			this.player.kBind(KalturaPlayerEvents.BUFFER_START_EVENT, onBuffer);
			this.player.kBind(KalturaPlayerEvents.MEDIA_ERROR, (error) => {
				(window.Rollbar || console).error(error.toString());
			});
		}
	}

	seekTo(amount: number) {
		if (this.playKitPlayer) {
			this.playKitPlayer.seekToLiveEdge(amount);
		} else {
			this.player.sendNotification(KalturaNotificationName.DO_SEEK, amount);
		}
	}

	getCurrentTime() {
		if (this.playKitPlayer) {
			return this.playKitPlayer.currentTime;
		} else {
			return Number(this.player.evaluate('{video.player.currentTime}'));
		}
	}

	getDuration() {
		if (this.playKitPlayer) {
			return this.playKitPlayer.duration;
		} else {
			return Number(this.player.evaluate('{duration}'));
		}
	}

	getSecondsLoaded() {
		if (this.playKitPlayer) {
			return this.playKitPlayer.buffered;
		} else {
			const percentBuffered = this.player.evaluate(`{video.buffer.percent}`);
			if (this.player) {
				return this.getDuration() * Number(percentBuffered);
			}
		}
		return null;
	}

	stop() {
		if (this.playKitPlayer) {
			this.playKitPlayer.pause();
		} else {
			this.player.sendNotification(KalturaNotificationName.DO_STOP);
		}
	}

	render() {
		return <div css={KalturaStyles} id={this.state.targetId}></div>;
	}
}
