import React, { ReactElement } from 'react';

import render from 'dom-serializer';
import { Element } from 'domhandler';
import { domToReact } from 'html-react-parser';

import { Offline } from '~/components/Offline';

import {
	BigIdea,
	Header,
	PrimarySource,
	WebtextBlockquote,
	WebtextCallout,
	WebtextExample,
	WebtextPullQuote,
	WebtextRefTippy,
	WebtextTippy
} from '../components';

import type { Props as TextProps } from '../Text';

/**
 * Footnote references can also be placed inside ref elements with the same attributes as citation refs.
 * To differentiate them we need to check if the source IDs are referencing the provided citations in TextProps.
 *
 * If it references a provided citation: it's a citation ref that should be rendered with Tippy
 * If it doesn't: it's a footnote ref
 */
const checkForCitationRef = (element: Element, textProps: TextProps): boolean => {
	const { tagName, attribs } = element;
	const { citationReferences } = textProps;
	if (tagName !== 'ref' || !attribs['data-source-ids']) {
		return false;
	}

	const dataSourceIds = attribs['data-source-ids'].split(' ');
	const sourceCitationsReferences = dataSourceIds.filter(
		(sourceId) => citationReferences[sourceId]?.reference
	);

	return sourceCitationsReferences.length > 0;
};

export const checkForComponent = (
	element: Element,
	textProps: TextProps,
	parentElement: Element
): ReactElement | void => {
	/**
	 * Elements should probably have attributes if we are considering swapping them for components,
	 * with the exception of blockquotes which may have none
	 */
	if (!element.attribs && element.tagName?.toLowerCase() !== 'blockquote') return;

	if (checkForCitationRef(element, textProps)) {
		return <WebtextRefTippy element={element} textProps={textProps} />;
	}

	if (element.tagName?.toLowerCase() === 'blockquote') {
		const hideQuotationIcon =
			parentElement?.attribs['class']?.split(' ')?.includes('webtext-callout') ||
			parentElement?.attribs['class']?.includes('webtext-primary-source');
		return (
			<WebtextBlockquote
				element={element}
				textProps={textProps}
				showQuotationIcon={!hideQuotationIcon}
			/>
		);
	}

	/**
	 * All of the conditions below here are class based
	 */
	if (!element.attribs['class']) return;

	if (element.attribs['class'].includes('webtext-big-idea')) {
		return <BigIdea element={element} textProps={textProps} />;
	}

	if (element.attribs['class'].includes('showtip')) {
		return <WebtextTippy element={element} textProps={textProps} />;
	}
	if (element.attribs['class'].split(' ').includes('webtext-callout')) {
		return <WebtextCallout element={element} textProps={textProps} />;
	}

	if (
		element.attribs['class'].includes('webtext-callout-gutter') ||
		element.attribs['class'].includes('webtext-callout-heading')
	) {
		return <Header element={element} textProps={textProps} />;
	}

	if (element.attribs['class'].includes('webtext-primary-source')) {
		return <PrimarySource element={element} textProps={textProps} />;
	}

	// Will match both the plain and questions examples
	if (/webtext(-\w+)?-example/.test(element.attribs['class'])) {
		return <WebtextExample element={element} textProps={textProps} />;
	}

	if (element.attribs['class'].includes('webtext-pull-quote')) {
		return <WebtextPullQuote element={element} textProps={textProps} />;
	}
};

export const checkForSpecialCase = (
	element: Element,
	textProps: TextProps
): ReactElement | void => {
	// workaround for https://github.com/remarkablemark/html-react-parser/issues/301
	// see T-29144, T-86148
	if (element.name === 'math') {
		return <span dangerouslySetInnerHTML={{ __html: render(element) }} />;
	}

	if (!element.attribs) {
		return;
	}

	if (element.attribs.class === 'footnote-ref' || element.attribs.class === 'footnote-anchor') {
		const linkText = domToReact(element.children);
		/** Whether this is a footnote reference in the body of the webtext (as opposed to one at the bottom of the page) */
		const isFootnoteRef = element.attribs?.class === 'footnote-ref';

		return (
			// this `<a>` doesn't have an href, but it acts like a same-page link;
			// we need to do some extra processing so we can't just use href
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				className={element.attribs['class']}
				data-citation-id={element.attribs['name']}
				data-ssml={`{"sub":{"alias":"${isFootnoteRef ? 'See ' : ''}footnote ${linkText}."}}`}
				tabIndex={-1}
				role="link"
				aria-label={
					isFootnoteRef ? `footnote ${linkText}` : `return to footnote ${linkText} reference`
				}
				onClick={(event) => {
					const citationId = element.attribs['href'].replace('#', '');
					let footnoteAnchor = document.querySelector(`[data-citation-id="${citationId}"]`);
					if (!footnoteAnchor) {
						footnoteAnchor = document.querySelector(`[data-citation-id="fn-${citationId}"]`);
					}
					textProps.onCitationClicked(
						event.target as HTMLAnchorElement,
						footnoteAnchor as HTMLAnchorElement
					);
				}}>
				{linkText}
			</a>
		);
	}

	if (element.attribs.class?.match(/\bwebtext-investigation-objective\b/)) {
		return (
			<div className={element.attribs.class} role="heading" aria-level={4}>
				{domToReact(element.children)}
			</div>
		);
	}

	if (element.attribs.class?.match(/\bwebtext-page-instruction\b/)) {
		if (element.name === 'div' || element.name === 'p') {
			// a <p> tag is used to ensure we style these as <p> tags
			// which have some bottom margin, whereas <div> tags don't,
			// at least not for non-UV themes
			return (
				<p
					className={element.attribs.class}
					id={element.attribs['id']}
					role="heading"
					aria-level={4}>
					{domToReact(element.children)}
				</p>
			);
		}
	}

	/**
	 * Audio timecode links, will not always have a `end` attribute
	 */
	if (element.attribs['waveform-player-ref'] && element.attribs['start']) {
		return (
			<a
				data-waveform-player-ref={element.attribs['waveform-player-ref']}
				data-start={element.attribs['start']}
				{...(element.attribs['end'] && { ['data-end']: element.attribs['end'] })}
				onClick={() =>
					textProps.playMediaSnippet(
						element.attribs['waveform-player-ref'],
						element.attribs['start'],
						element.attribs['end']
					)
				}>
				{domToReact(element.children)}
			</a>
		);
	}

	if (
		element.attribs['lms-course-redirect'] &&
		element.attribs.class?.match(/\bwebtext-big-link\b/)
	) {
		if (!textProps.online) {
			return <Offline mobile elementName="LMS" interactionVerb="visit" />;
		} else {
			return (
				<a
					href={element.attribs['href'] ?? '#'}
					className={element.attribs['class']}
					target={element.attribs['target'] ?? '_blank'}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						textProps.onLmsCourseRedirect?.(element.attribs['lms-course-redirect']);
					}}>
					{domToReact(element.children)}
				</a>
			);
		}
	}
};

export const checkForBBCode = (payload: string): string =>
	payload
		.replace(/\[i]/g, '<em>')
		.replace(/\[\/i]/g, '</em>')
		.replace(/\[b]/, '<strong>')
		.replace(/\[\/b]/, '</strong>')
		.replace(/ {2}\[br]/, '<br/>')
		.trim();

export const checkForSpecialTextContent = (
	element: Element,
	textProps: TextProps,
	parentElement?: Element
): ReactElement | void => {
	return (
		checkForComponent(element, textProps, parentElement) || checkForSpecialCase(element, textProps)
	);
};

export const addClassToElement = (element: Element, className: string): void => {
	if (element.attribs.class) {
		if (!element.attribs.class.split(' ').includes('visually-hidden')) {
			element.attribs.class = `${element.attribs.class} ${className}`;
		}
	} else {
		element.attribs.class = className;
	}
};
