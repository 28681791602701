import { css } from '@emotion/react';

import { fonts, breakpoints } from '~/styles/themes';

import { upperLatinListOverride } from './customListItemBullets';

export const universalVelvetStyles = (theme) => {
	return css`
		// Headings
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: ${theme.colors.text};
		}

		h1 {
			margin-top: 0;
			font-size: 56px;
			font-weight: 900;
			letter-spacing: 0;
			line-height: 60px;
		}

		// todo: fix this hack
		h2.webtext-figure-number,
		div.webtext-figure-number {
			font-weight: bold !important;
			font-size: 1em !important;
		}

		h2,
		h2.webtext-page-title {
			font-size: 40px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 50px;
		}

		h3,
		h3.webtext-page-title {
			font-size: 32px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 42px;
		}

		h4,
		h4.webtext-page-title {
			font-size: 24px;
			font-weight: bold;
			line-height: 30px;
			letter-spacing: normal;
		}

		h5 {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 3.2px;
			text-transform: uppercase;
			line-height: 20px;
		}

		h6 {
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 2.8px;
			text-transform: uppercase;
			line-height: 18px;
		}

		@media (max-width: ${breakpoints.small}) {
			h1,
			h1.webtext-page-title {
				font-size: 28px;
				letter-spacing: 0.4px;
				line-height: 28px;
			}

			h2,
			h2.webtext-page-title {
				font-size: 23px;
				letter-spacing: 0.26px;
				line-height: 29px;
			}

			h3,
			h3.webtext-page-title {
				font-size: 20px;
				letter-spacing: 0;
				line-height: 25px;
			}

			h4,
			h4.webtext-page-title {
				font-size: 18px;
				line-height: 23px;
			}

			h5 {
				font-size: 14px;
				letter-spacing: 2.8px;
				line-height: 18px;
			}

			h6 {
				font-size: 12px;
				letter-spacing: 2.4px;
				line-height: 15px;
			}
		}

		// Lists
		ol.webtext-twg-outline,
		ol.webtext-outline {
			margin: 2em 5em;
			list-style: none;

			@media (max-width: ${breakpoints.small}) {
				margin: 1em 2.5em;
			}

			li {
				&::before {
					content: '.' counter(list-item, upper-roman);
				}

				ol {
					list-style: none;

					li {
						&::before {
							content: '.' counter(list-item, upper-alpha);
						}

						ol {
							list-style: none;

							li {
								&::before {
									content: '.' counter(list-item);
								}
							}
						}
					}
				}
			}
		}

		ol {
			list-style: none;
			margin-left: 60px;
			margin-top: 22px;
			margin-bottom: 50px;

			> li {
				font-family: ${theme.fonts.webtext};
				font-size: 20px;
				color: #000000;
				margin-top: 0;
				margin-bottom: 17px;
				letter-spacing: 0;
				line-height: 34px;

				&::before {
					content: '.' counter(list-item, decimal);
					font-family: ${fonts['haas-grotesk-medium']};
					color: ${theme.colors.primary};
					display: inline-block;
					width: 1em;
					font-size: inherit;
					margin-left: -1.5em;
					margin-right: 0.5em;
					text-align: right;
					direction: rtl;
				}

				@media (max-width: ${breakpoints.small}) {
					font-size: 15px;
					letter-spacing: 0.22px;
					line-height: 26px;
				}
			}

			&.webtext-ciag-centralideas-list {
				list-style: none;
				margin-top: 10px;

				li {
					font-family: ${theme.fonts.app};
					margin-bottom: 20px;
					line-height: 26px;

					&::before {
						content: '.' counter(list-item, upper-roman);
						font-family: ${theme.fonts.webtext};
						font-weight: bold;
						color: ${theme.colors.primary};
						display: inline-block;
						width: 1em;
						font-size: 22px;
						margin-left: -1.5em;
						margin-right: 0.5em;
						text-align: right;
						direction: rtl;
						white-space: nowrap;
					}
				}
			}

			@media (max-width: ${breakpoints.small}) {
				margin-left: 30px;
				margin-top: 18px;
			}
		}

		${upperLatinListOverride(css`'.' counter(list-item, upper-latin)`)}

		ul {
			list-style: square;
			margin-left: 60px;
			margin-top: 22px;
			margin-bottom: 50px;

			li {
				font-family: ${theme.fonts.webtext};
				font-size: 20px;
				color: #000000;
				letter-spacing: 0;
				line-height: 34px;
				margin-bottom: 17px;

				&::marker {
					font-size: 24px;
					color: ${theme.colors.primary};

					@media (max-width: ${breakpoints.small}) {
						font-size: 15px;
					}
				}

				@media (max-width: ${breakpoints.small}) {
					font-size: 15px;
					letter-spacing: 0.22px;
					line-height: 26px;
				}
			}

			@media (max-width: ${breakpoints.small}) {
				margin-left: 30px;
				margin-top: 18px;
			}
		}

		// Other content

		p,
		blockquote,
		blockquote > p {
			font-size: 20px;
			letter-spacing: 0px;
			line-height: 1.7;
			color: black;
			font-family: ${theme.fonts.webtext};

			@media (max-width: ${breakpoints.small}) {
				font-size: 15px;
				letter-spacing: 0.22px;
				line-height: 26px;
			}
		}

		p.webtext-page-instruction {
			font-size: 16px;
		}

		.webtext-page-instruction {
			position: relative;
			background: #f5f5f5;
			font-family: ${theme.fonts.app};
			font-size: 16px;
			color: #000000;
			letter-spacing: 0;
			line-height: 27px;
			margin: 0.95em;
			margin-top: 2em;
			margin-left: 18px;
			margin-bottom: 40px;
			padding-left: 27px;
			padding-top: 2em;
			padding-bottom: 24px;

			.webtext-page-instruction-label {
				position: absolute;
				background-color: ${theme.colors.primary};
				font-weight: bold;
				font-size: 18px;
				color: white;
				height: 36px;
				line-height: 35px;
				margin-left: 1em;
				left: -30px;
				top: -17px;
				padding-left: 16px;
				padding-right: 16px;
				text-align: center;
				min-width: 128px;
				max-width: 268px;
				white-space: nowrap;
				box-sizing: border-box;

				&::after {
					left: 100%;
					top: 0px;
					content: ' ';
					position: absolute;
					border-top: 18px solid transparent;
					border-bottom: 18px solid transparent;
					border-left: 12px solid ${theme.colors.primary};
				}
			}
		}

		p.readable + .webtext-callout {
			margin-top: 40px;
		}

		.webtext-investigation-objective {
			color: ${theme.colors.black};
			line-height: 24px;
			font-size: 17px;
			margin: 20px 0 40px;
			padding-left: 14px;
			border-left: 10px solid ${theme.colors.primary};
			font-family: ${theme.fonts.app};

			strong {
				font-size: 18px;
			}

			@media (max-width: ${breakpoints.small}) {
				margin: 10px 0 30px;
				padding-left: 10px;
				font-size: 16px;
				line-height: 22px;
				border-left-width: 6px;
			}

			&:after {
				display: none;
			}
		}

		.footnote-ref {
			font-weight: 500;
		}

		.webtext-article-source {
			border: none;
			color: ${theme.colors.primary};
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			padding: 0;
			margin-bottom: 20px;

			span {
				padding: 0;
			}
		}
	`;
};
