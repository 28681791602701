import React, { FC, useMemo } from 'react';

import { WaveformAudioPlayer } from '~/components/pageElements';

import { FigureProps } from '../Figure';

import type { FigureElement } from '~/types/WebtextManifest';

interface Props {
	figure: Partial<FigureElement>;
	audioFigureProps: FigureProps['audioFigureProps'];
}

const NOOP = () => undefined;

const AudioFigure: FC<Props> = (props) => {
	const { figure, audioFigureProps: { waveformAudioPlayerRef } = {} } = props;

	const { src, waveformUrl } = useMemo(() => {
		const parser = new DOMParser();
		const parsed = parser.parseFromString(String(figure.payload), 'text/html');
		const waveformDiv = parsed.querySelector('div[data-waveform]');
		return {
			src: waveformDiv.getAttribute('src'),
			waveformUrl: waveformDiv.getAttribute('data-waveform')
		};
	}, [figure.payload]);

	return (
		<WaveformAudioPlayer
			ref={waveformAudioPlayerRef}
			src={src}
			waveformUrl={waveformUrl}
			onPlay={NOOP}
			onError={(e) => console.error(e)}
			familyId={figure.family_id}
			className="webtext-figure-asset webtext-figure-asset-audio"
		/>
	);
};

export default AudioFigure;
