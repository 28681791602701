import React, { ReactElement } from 'react';

import { domToReact, Element } from 'html-react-parser';

import { WebtextTippy, WebtextTippyProps } from '../WebtextTippy';

type Props = Omit<WebtextTippyProps, 'content'>;

export const WebtextRefTippy: React.FC<Props> = (props) => {
	const { element, textProps } = props;

	return domToReact([element], {
		replace: function RefTippy(element) {
			// Typescript type narrowing check
			if (!(element instanceof Element && element.attribs)) return;

			const { attribs } = element;

			// Ignore all non-ref elements
			const dataSourceFamilyId = attribs?.['data-source-family-id'];
			if (!dataSourceFamilyId) return;

			const dataSourceReference = textProps.citationReferences[dataSourceFamilyId].reference;
			if (!dataSourceReference) {
				console.warn(`Data source ${dataSourceFamilyId} wasn't found for the ref element`);
				return;
			}

			attribs['is'] = 'soomoRef';

			/**
			 * This is a citation, we need to strip HTML tags from the `text` value and render the
			 * citation for the `element` value.
			 */
			const tippyContent = {
				text: dataSourceReference.replace(/(<([^>]+)>)/gi, ''),
				element: <span dangerouslySetInnerHTML={{ __html: dataSourceReference }} />
			};

			return <WebtextTippy element={element} content={tippyContent} textProps={textProps} />;
		}
	}) as ReactElement;
};
